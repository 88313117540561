import callApi, { BaseResponseDto } from "./baseRequest";

const route = 'material';

interface Description {
    language: string,
    description: string,
    code: string
}

export interface Material {
    id: string,
    issuer: string,
    materialCode: string,
    pvc: boolean,
    descriptions: Description[],
    activitySector: string
};

interface MaterialResponse {
    materials: Material[],
    success: boolean,
    error: string,
    errorEN: string,
    errorES: string,
    errorPT: string    
};

interface MaterialGroupedResponse {
    materials: TabMaterial[],
    success: boolean,
    error: string,
    errorEN: string,
    errorES: string,
    errorPT: string    
};

export interface TabMaterial {
    materialCode: string,
    materialDescription: string
}

export function getMaterialsByIssuer(issuerCode: string): Promise<MaterialResponse> {
    const result: MaterialResponse = {
        materials: [],
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',           
        success: false
    };

    const response = callApi('GET', `${route}/list-products/${issuerCode}`)
    .then(resp => {
        if (resp?.length > 0 ?? false) {
            resp?.map((r: any) => {
                let material: Material = {
                    materialCode: r.materialCode,
                    issuer: r.issuer,
                    descriptions: r.descriptions,
                    id: r.id,
                    pvc: r.pvc,
                    activitySector: r.activitySector
                };

                result.materials.push(material);
            });
            result.success = true;
        }
        else {            
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;            
        }

        return result;
    })
    .catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorEN = err?.errorEN;
        result.errorES = err?.errorES;     

        return result;
    })

    return response;
};

export function getMaterialsByIssuerCurrentDate(issuerCode: string): Promise<MaterialGroupedResponse> {
    const result: MaterialGroupedResponse = {
        materials: [],
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',            
        success: false
    };

    const response = callApi('GET', `${route}/bought-in-month/${issuerCode}`)
    .then(resp => {
        if (resp?.data?.length > 0 ?? false) {
            resp?.data?.map((r: any) => {
                let material: TabMaterial = {
                    materialCode: r.materialCode,
                    materialDescription: r.materialDescription
                };

                result.materials.push(material);
            });
            result.success = true;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;     
        }

        return result;
    })
    .catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorEN = err?.errorEN;
        result.errorES = err?.errorES;     

        return result;
    })

    return response;
};

export function getMaterialsByIssuerBought(issuerCode: string): Promise<MaterialGroupedResponse> {
    const result: MaterialGroupedResponse = {
        materials: [],
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',            
        success: false
    };

    const response = callApi('GET', `${route}/bought/${issuerCode}`)
    .then(resp => {
        if (resp?.data?.length > 0 ?? false) {
            resp?.data?.map((r: any) => {
                let material: TabMaterial = {
                    materialCode: r.materialCode,
                    materialDescription: r.materialDescription
                };

                result.materials.push(material);
            });
            result.success = true;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;     
        }

        return result;
    })
    .catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorEN = err?.errorEN;
        result.errorES = err?.errorES;     

        return result;
    })

    return response;
};

export function getMaterialHasPrice(issuerCode: string, materialCode: string): Promise<BaseResponseDto<boolean>> {
    const result: BaseResponseDto<boolean> = {
        data: false,
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',            
        success: false
    };

    const response = callApi('GET', `${route}/has-price/${issuerCode}/${materialCode}`)
    .then(resp => {
        if (resp?.data ?? false) {
            result.success = true;
            result.data = resp.data
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;    
        }

        return result;
    })
    .catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorEN = err?.errorEN;
        result.errorES = err?.errorES;     

        return result;
    })

    return response;
};