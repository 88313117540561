import './UniInputFile.scss';
import uploadFile from '../../assets/icons/upload-file.svg';
import removeFile from '../../assets/icons/remove-file.svg';
import removeFileGreen from '../../assets/icons/remove-file-green.svg';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import UniModal from '../UniModal/UniModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';

interface UniInputFileProps {
    canEdit: boolean
    files: (File | UniInputFileReference)[]
    label: string    
    fileExtensions: string[]
    onFileAdd?: (files: File[]) => void
    onFileRemove?: (file: (File | UniInputFileReference)) => void
    fileRemoveModalTitle: string
    fileRemoveModalMessage?: string
    fileRemoveConfirmation: boolean
    fileIsRemoving?: boolean
    fileIsAdding?: boolean
};

export interface UniInputFileReference {
    id: string
    name: string
    url: string
    type: string
}

const UniInputFile = (props: UniInputFileProps) => {
    const [files, setFiles] = useState<(File | UniInputFileReference)[]>([]);
    const [selectedFile, setSelectedFile] = useState<number>();
    const [selectedImageFile, setSelectedImageFile] = useState<string>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    useEffect(() => {
        if(!props.fileIsRemoving) {
            hideConfirmFileDeleteModal();
        }
    }, [props.fileIsRemoving])
    
    const attachFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = props.fileExtensions.join(',');
        input.multiple = true;
        input.addEventListener('change', () => {
            const filesToAdd = Array.from(input.files ?? []);
            if(props.onFileAdd) {
                props.onFileAdd([...filesToAdd]);
            }
            setFiles([...filesToAdd]);
          });        
        input.click();
    }

    const addFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = props.fileExtensions.join(',');
        input.multiple = true;
        input.addEventListener('change', () => {
            const filesToAdd : File[] = [];
            Array.from(input.files ?? []).forEach(file => {
                if(!files.find(x => x.name === file.name)) {
                    filesToAdd.push(file);
                }
            })
            if(props.onFileAdd && filesToAdd.length > 0) {
                props.onFileAdd([...filesToAdd]);
            }           
            setFiles([...files, ...filesToAdd]);
          });        
        input.click();
    }

    const confirmFileDelete = (idx: number) => {
        setSelectedFile(idx);

        if(props.fileRemoveConfirmation) {
            setShowDeleteModal(true);
        } else {
            deleteFile(idx);
        }
    }

    const hideConfirmFileDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedFile(undefined);
    }

    const deleteFile = (idx: number) => {
        if(props.onFileRemove) {
            props.onFileRemove(files[idx]);
        }
        files.splice(idx, 1);
        setFiles([...files]);
    }
    
    const displayImageOrDownloadFile = (idx: number) => {
        const allowedFileImageTypes = ['image/png', 'image/jpeg', 'image/gif'];
        const file = files[idx] instanceof File ? files[idx] as File : files[idx] as UniInputFileReference;
        if(allowedFileImageTypes.indexOf(files[idx].type) > -1) {
            setShowImageModal(true);
            if(file instanceof File) {
                setSelectedImageFile(URL.createObjectURL(file));
            } else {
                setSelectedImageFile(file.url);
            }
        } else {
            let fileUrl = '';
            if(file instanceof File) {
                fileUrl = URL.createObjectURL(file);
            } else {
                fileUrl = file.url;
            }
            const anchorElement = document.createElement('a');
            
            anchorElement.href = fileUrl;
            anchorElement.download = file.name;
            anchorElement.style.display = 'none';

            document.body.appendChild(anchorElement);

            anchorElement.click();
            anchorElement.remove();

            URL.revokeObjectURL(fileUrl);
        }
    }

    const hideImageModal = () => {
        setShowImageModal(false);
        setSelectedImageFile(undefined);
    }

    return (
        <div className='uni-input-file'>
            {files?.length === 0 ? (
                props.canEdit && (
                    <div className='no-files'>
                        <img src={uploadFile} alt='icone de upload' />
                        <button
                            onClick={() => attachFiles()}
                        >
                            {t('attachFile')}
                        </button>
                        <span className='permitted-files'>{t('permittedFiles') + props.fileExtensions.join(', ')}</span>
                    </div>
                )
            ) : (
                <div className='files'>
                    <label>{props.label}</label>
                    {
                        files.map((file, idx) => (
                            <div key={idx} className='file'>
                                <span onClick={()=> displayImageOrDownloadFile(idx)}>{file.name}</span>
                                {props.canEdit && (                                    
                                    props.fileIsAdding ? (
                                        <Spinner/>
                                    ) : (
                                        <img 
                                            src={removeFile} 
                                            alt='icone de exclusão' 
                                            onClick={() => confirmFileDelete(idx)}
                                        />
                                    )
                                )}
                            </div>                            
                        ))
                    }
                    {props.canEdit && (
                        <button 
                            onClick={() => addFiles()}                            
                            disabled={props.fileIsAdding}
                        >
                            {
                                props.fileIsAdding ? 
                                <Spinner color="#fff"/> : t('addFile')
                            }
                        </button>
                    )}
                    <UniModal
                        icon={removeFileGreen}
                        title={props.fileRemoveModalTitle}
                        message={props.fileRemoveModalMessage ?? ''}
                        show={showDeleteModal}
                        onHide={() => hideConfirmFileDeleteModal()}
                    >    
                        <button 
                            className='button-close' 
                            onClick={() => hideConfirmFileDeleteModal()}>
                            {t('close')}
                        </button>
                        <button 
                            className='button-confirm' 
                            onClick={() => deleteFile(selectedFile ?? -1)}
                            disabled={props.fileIsRemoving}>
                            {
                                props.fileIsRemoving ? 
                                <Spinner color="#fff"/> : t('confirm')
                            }
                        </button>
                    </UniModal>
                    {showImageModal && (
                        <div className='selected-image-wrapper' onClick={() => hideImageModal()}>
                            <img src={selectedImageFile} alt='imagem selecionada' />
                            <FontAwesomeIcon icon={faXmark} style={{height: 40}} color={'#fff'}/>
                        </div>
                    )}                    
                </div>
            )} 
        </div>
    );
}

export default UniInputFile;