import callApi, {  BaseResponse, BaseResponseDto, BaseResponsePagedDto, callApiFile } from "./baseRequest";

const route = 'call-center';

export enum TicketStatusEnum {
    Pending = 1,
    Served = 2,
    Finished = 3
}

export interface Response {
    id: string,
    number: number,
    createdAt?: Date,
    ticketLevel?: ResponseTicketLevel,
    status?: TicketStatusEnum
}

export interface NewTicket {
    description: string,
    telephone: string,    
    ticketLevelId: string
}

export interface AlterTicket {
    status: TicketStatusEnum,
    sectorId: string
}

export interface ResponseTicket {
    id: string,
    number: number,
    createdAt: Date,
    description: string,
    telephone: string,    
    status: TicketStatusEnum,
    sector?: ResponseSector,
    ticketLevel?: ResponseTicketLevel,
    files: ResponseTicketFile[],
    histories: ResponseTicketHistory[]
}

export interface ResponseSector {
    id: string,
    namePT: string,
    nameES: string,
    nameEN: string
}

export interface ResponseTicketLevel {
    id: string,
    descriptionPT: string,
    descriptionES: string,
    descriptionEN: string
}

export interface ResponseTicketFile {
    id: string,
    urlBlob: string,
    name: string,
    isUnipar: boolean
}

export interface ResponseTicketHistory {
    id: string,
    createdAt: Date,
    description: string,
    isUnipar: boolean
}

export function getData(
    take: number,
    skip: number,
    issuer?: string,
    number?: string,
    ticketLevelId?: string,
    status?: string,
    sort?: string,
    language?: string,
    initialDate?: string,
    finalDate?: string): Promise<BaseResponsePagedDto<Response[]>> {
    const result: BaseResponsePagedDto<Response[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: [],
        success: false,
        currentTake: take,
        totalItems: 0,
        totalPages: 0
    };

    let url = `${route}/list-tickets/${language}?issuer=${issuer}&take=${take}&skip=${skip}&number=${number}&status=${status}&ticketLevelId=${ticketLevelId}&sort=${sort}&initialDate=${initialDate}&finalDate=${finalDate}`;

    const response = callApi(
        'GET',
        url
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
            result.totalItems = resp?.totalItems;
            result.totalPages = resp?.totalPages;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getSectors(): Promise<BaseResponseDto<ResponseSector[]>> {
    const result: BaseResponseDto<ResponseSector[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: [],
        success: false
    };

    let url = `${route}/list-sectors`;

    const response = callApi(
        'GET',
        url
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getTicketLevels(): Promise<BaseResponseDto<ResponseTicketLevel[]>> {
    const result: BaseResponseDto<ResponseTicketLevel[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: [],
        success: false
    };

    let url = `${route}/list-ticket-levels`;

    const response = callApi(
        'GET',
        url
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getTicket(ticketId: string): Promise<BaseResponseDto<ResponseTicket>> {
    const result: BaseResponseDto<ResponseTicket> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: {
            id: '',
            number: 0,
            createdAt: new Date(),
            description: '',
            telephone: '',
            sector: {
                id: '',
                nameEN: '',
                nameES: '',
                namePT: ''
            },
            status: TicketStatusEnum.Pending,
            ticketLevel: {
                id: '',
                descriptionEN: '',
                descriptionES: '',
                descriptionPT: ''
            },
            files: [],
            histories: []
        },
        success: false
    };

    let url = `${route}/ticket/${ticketId}`;

    const response = callApi(
        'GET',
        url
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function createTicket(ticket: NewTicket, files: File[], issuer: string = '') : Promise<BaseResponseDto<ResponseTicket>>
{    
    const result: BaseResponseDto<ResponseTicket> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: {
            id: '',
            createdAt: new Date(),
            description: '',
            number: 0,
            status: TicketStatusEnum.Pending,
            telephone: '',
            sector: undefined,
            ticketLevel: undefined,
            files: [],
            histories: []
        },
        success: false,
        errors: []
    };

    const response = callApiFile(
        'POST', 
        `${route}/create-ticket/${issuer}`,
        files,
        ticket
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errors = resp?.data;
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
            result.data = resp?.data;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.data = err?.data;
        result.errors = err?.data;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    });

    return response;
};

export function addTicketFile(ticketId: string, files: File[]) : Promise<BaseResponse>
{    
    const result: BaseResponse = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        success: false
    };

    const response = callApiFile(
        'POST', 
        `${route}/add-ticket-file/${ticketId}`,
        files
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    });

    return response;
};

export function removeTicketFile(ticketFileId: string) : Promise<BaseResponse>
{    
    const result: BaseResponse = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        success: false
    };

    const response = callApi(
        'DELETE', 
        `${route}/remove-ticket-file/${ticketFileId}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    });

    return response;
};

export function createTicketReply(ticketId: string, message: string) : Promise<BaseResponseDto<ResponseTicketHistory>>
{    
    const result: BaseResponseDto<ResponseTicketHistory> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: {
            id: '',
            createdAt: new Date(),
            description: '',
            isUnipar: false
        },
        success: false,
        errors: []
    };

    const response = callApiFile(
        'POST', 
        `${route}/create-reply-ticket/${ticketId}`,
        [],
        {description: message}
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errors = resp?.data;
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
            result.data = resp?.data;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.data = err?.data;
        result.errors = err?.data;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    });

    return response;
};

export function alterTicket(ticketId: string, alterTicket: AlterTicket, language: string,) : Promise<BaseResponse>
{    
    const result: BaseResponse = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        success: false
    };

    const response = callApiFile(
        'PUT', 
        `${route}/alter-ticket/${language}/${ticketId}`,
        [],
        alterTicket
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    });

    return response;
};