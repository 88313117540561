import { Customer } from "../store/user";
import callApi, { BaseResponse, BaseResponseDto } from "./baseRequest"

interface GetTokenRequest {
    email: string,
    password: string,
    token: string
}

interface GetTokenResponse extends BaseResponse {
    token: string,
    userId: string,
    customers: any[],
    recaptcha: boolean,
    showCustomerSurvey: boolean,
    hasNotices: boolean
}

interface GetRecaptchaRequest {
    token: string,
}

interface GetRecaptchaResponse {
    passed: boolean,
    message: string,
    messagePT: string,
    messageES: string,
    messageEN: string
}

interface PostValidationRegister {
    name: string;
    document: string;
    email: string;
    phone: string;
    resendEmail: boolean;
}

export enum RequestType {
    Register = 1,
    ResetPassword = 2,
    FirstAccess = 3,
}

export enum Profile {
    Read = 1,
    LogOperator = 2,
    Operator = 3,
    Adm = 4,
    Financial = 5
}

export interface UsersResponse {
    email: string,
    name: string,
    createdAt: Date,
    firstAcessAt: Date,
    lastAcessAt: Date,
    active: boolean,
    profile: Profile,
    id: string,
}

export interface CustomerResponse {
    customerId: string,
    issuer: string,
    customerName: string,
    cpfCnpj: string,
    userId: string,
    enabledOrderAfterHour: boolean
}

interface UsersByCustomerResponse {
    users: UsersResponse[]
}

interface CustomerByUserResponse {
    customers: CustomerResponse[]
}

export function getProfileByStr(profile: string) {

    switch(Number(profile)) {
        case Profile.Adm:
            return Profile.Adm;
        case Profile.Read:
            return Profile.Read;
        case Profile.LogOperator:
            return Profile.LogOperator;
        case Profile.Operator:
            return Profile.Operator;
        default:
            return Profile.Read;
    }
}

export function getProfileToStr(profile: string) {

    switch(Number(profile)) {
        case Profile.Adm:
            return 'Admin';
        case Profile.Read:
            return 'Visualizador';
        case Profile.LogOperator:
            return 'Operador Log';
        case Profile.Operator:
            return 'Operador';
        default:
            return 'Visualizador';
    }
}

const route = 'user';

export function getToken(request: GetTokenRequest): Promise<GetTokenResponse> {
    const result: GetTokenResponse = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        token: '',
        userId: '',
        customers: [],
        recaptcha: false,
        showCustomerSurvey: false,
        hasNotices: false
    };

    const response = callApi('POST', `${route}/token`, request)
    .then(response => {
        result.success = true;
        result.token = response?.data?.token;
        result.userId = response?.data?.userId;
        result.customers = response?.data?.customers;
        result.showCustomerSurvey = response?.data?.showCustomerSurvey;
        result.hasNotices = response?.data?.hasNotices;

        return result;
    }).catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;
        result.recaptcha = err?.recaptcha;

        return result;
    });

    return response;
}

export function getRecaptcha(request: GetRecaptchaRequest): Promise<BaseResponseDto<GetRecaptchaResponse>> {
    const result: BaseResponseDto<GetRecaptchaResponse> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: { passed: false, message: '', messageEN: '', messageES: '', messagePT: ''}
    };

    const response = callApi('POST', `${route}/validate-recaptcha`, request)
    .then(response => {
        result.success = true;
        result.data = response.data;


        return result;
    }).catch(err => {
        result.error = err.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;
        result.data = err.data;

        return result;
    });

    return response;
}

export function sendRegisterRequest(request: PostValidationRegister): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: null
    };

    const response = callApi('POST', `${route}/validation-register`, request)
    .then(response => {
        result.success = true;

        if (response.data) {
            result.data = response.data
        }
        return result;
    }).catch(err => {
        result.error = err.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;

        return result;
    });

    return response;
}

export function validateForgotPassEmail(email: string, requestType: RequestType = RequestType.ResetPassword): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: null
    };

    const response = callApi('POST', `${route}/request-reset-pssw`, {email: email, requestType: requestType})
    .then(response => {
        result.success = true;

        if (response.data) {
            result.data = response.data
        }
        return result;
    }).catch(err => {
        result.error = err.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;

        return result;
    });

    return response;
}

export function validateForgotPassCode(email: string, code: string, requestType: RequestType = RequestType.ResetPassword): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: null
    };

    const response = callApi('POST', `${route}/validate-code-reset-pssw`, {email: email, code: code, requestType: requestType})
    .then(response => {
        result.success = true;

        if (response.data) {
            result.data = response.data
        }
        return result;
    }).catch(err => {
        result.error = err.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;

        return result;
    });

    return response;
}

export function sendNewPass(email: string, code: string, newPass: string): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: null
    };

    const response = callApi('POST', `${route}/reset-pssw`, {email: email, code: code, newPassword: newPass})
    .then(response => {
        result.success = true;

        if (response.data) {
            result.data = response.data
        }
        return result;
    }).catch(err => {
        result.error = err.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;

        return result;
    });

    return response;
}

export function getUsersByCustomer(issuer: string): Promise<BaseResponseDto<UsersByCustomerResponse>> {
    const result: BaseResponseDto<UsersByCustomerResponse> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: {
            users: []
        }
    };

    const response = callApi('GET', `${route}/user-by-customer/${issuer}`)
        .then(response => {
            result.success = true;

            if (response.data) {
                result.data = response.data
            }
            return result;
        }).catch(err => {
            result.error = err.error;
            result.errorPT = err?.errorPT;
            result.errorES = err?.errorES;
            result.errorEN = err?.errorEN;

            return result;
        });

    return response;
}

export function getCustomersByUser(userId: string): Promise<BaseResponseDto<CustomerByUserResponse>> {
    const result: BaseResponseDto<CustomerByUserResponse> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: {
            customers: []
        }
    };

    const response = callApi('GET', `${route}/customers-by-user/${userId}`)
        .then(response => {
            result.success = true;

            if (response.data) {
                result.data = response.data
            }
            return result;
        }).catch(err => {
            result.error = err.error;
            result.errorPT = err?.errorPT;
            result.errorES = err?.errorES;
            result.errorEN = err?.errorEN;

            return result;
        });

    return response;
}

export function updateUser(userId: string, profile: Number, active: boolean, customers: CustomerResponse[]): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: null
    };

    const response = callApi('PUT', `${route}/${userId}`, {
        profile,
        active,
        customers
    })
        .then(response => {
            result.success = true;

            if (response.data) {
                result.success = true;
                result.data = response.data
            }
            return result;
        }).catch(err => {
            result.error = err.error;
            result.errorPT = err?.errorPT;
            result.errorES = err?.errorES;
            result.errorEN = err?.errorEN;

            return result;
        });

    return response;
}

export function getCustomersByIssuer(issuer: string): Promise<BaseResponseDto<Customer>> {
    const result: BaseResponseDto<Customer> = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false,
        data: {
            country: ``,
            cpfCnpj: ``,
            customerId: ``,
            issuer: ``,
            name: ``,
            searchTerm: ``,
            enabledOrderAfterHour: false
        }
    };

    const response = callApi('GET', `${route}/customers-by-issuer/${issuer}`)
        .then(response => {
            result.success = true;

            if (response.data) {
                result.data = response.data
            }
            return result;
        }).catch(err => {
            result.error = err.error;
            result.errorPT = err?.errorPT;
            result.errorES = err?.errorES;
            result.errorEN = err?.errorEN;

            return result;
        });

    return response;
}