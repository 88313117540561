import callApi, { BaseResponse, BaseResponseDto } from "./baseRequest";

const route = 'user-configuration'

export interface MailConfiguration{
    scheduledEmail: boolean,
    pending: boolean,
    departureEmail: boolean,
    entry: boolean,
    invoicedEmail: boolean,
    userId: string,
    customerCode: string,
    createdAt: Date,
    ModifiedAt: Date
}

interface MailResponse extends BaseResponse {

}

export function getEmptyMailConfiguration(): MailConfiguration {
    return {
        scheduledEmail: false,
        pending: false,
        departureEmail: false,
        entry: false,
        invoicedEmail: false,
        userId: ``,
        customerCode: ``,
        createdAt: new Date(),
        ModifiedAt: new Date()
    }
}

export function getMailUserConfiguration(userId: string, customerCode: string): Promise<BaseResponseDto<MailConfiguration>> {
    const result: BaseResponseDto<MailConfiguration> = {
        data: getEmptyMailConfiguration(),
        error: '',
        success: false
    };

    const response = callApi('GET', `${route}/user-configuration/${userId}?customerCode=${customerCode}`)
    .then(resp => {
        if (resp) {
            result.data = resp.data;
            result.success = true;
        }
        return result;
    })
    .catch(err => {
        result.error = err;

        return result;
    })

    return response;
};

export function userConfigurationCreateOrUpdate(request: MailConfiguration): Promise<MailResponse> {
    const result: MailResponse = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false
    };

    const response = callApi('POST', `${route}/create`, request)
    .then(response => {
        result.success = true;
        return result;
    }).catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;

        return result;
    });

    return response;
}