import { t } from "i18next";
import { TicketStatusEnum } from "../services/callCenterService";
import { StatusIntent } from "../types/orderIntent";
import { PixKeyType } from "../types/pixKeyType";

export const getDescriptionStatusIntent = (status: StatusIntent): string => {
    switch (status) {
        case StatusIntent.UniparValidation:
            return 'Análise Unipar';
        case StatusIntent.CustomerPendency:
            return 'Pendente Cliente';
        case StatusIntent.OrderConfirmed:
            return 'Pedido Agendado';
        case StatusIntent.Arrived:
            return 'Chegou no pátio';
        case StatusIntent.EntryWeight:
            return 'Entrada na fábrica';
        case StatusIntent.LoadAndSealed:
            return 'Carregando';
        case StatusIntent.Invoiced:
            return 'Faturado';
        case StatusIntent.Recused:
            return 'Recusado';
        case StatusIntent.Canceled:
            return 'Cancelado';
        case StatusIntent.EditSolicitation:
            return 'Análise de alteração';
        case StatusIntent.ReviewCancelation:
            return 'Análise de cancelamento';
        default:
            return 'Outro'
    }
}

export const getDescriptionStatusIntentLanguage = (status: StatusIntent, language: string): string => {
    if(language === '' || language == null || language === undefined || language?.toLowerCase() === 'pt')
    {
        switch (status) {
            case StatusIntent.UniparValidation:
                return 'Análise Unipar';
            case StatusIntent.CustomerPendency:
                return 'Pendente Cliente';
            case StatusIntent.OrderConfirmed:
                return 'Pedido Agendado';
            case StatusIntent.Arrived:
                return 'Chegou no pátio';
            case StatusIntent.EntryWeight:
                return 'Entrada na fábrica';
            case StatusIntent.LoadAndSealed:
                return 'Carregando';
            case StatusIntent.Invoiced:
                return 'Faturado';
            case StatusIntent.Recused:
                return 'Recusado';
            case StatusIntent.Canceled:
                return 'Cancelado';
            case StatusIntent.EditSolicitation:
                return 'Análise de alteração';
            case StatusIntent.ReviewCancelation:
                return 'Análise de cancelamento';
            default:
                return 'Outro'
        }
    }
    else if(language?.toLowerCase() === 'en')
    {
        switch (status) {
            case StatusIntent.UniparValidation:
                return 'Unipar Validation';
            case StatusIntent.CustomerPendency:
                return 'Customer Pendency';
            case StatusIntent.OrderConfirmed:
                return 'Order Confirmed';
            case StatusIntent.Arrived:
                return 'Arrived';
            case StatusIntent.EntryWeight:
                return 'Entry Weight';
            case StatusIntent.LoadAndSealed:
                return 'Load and Sealed';
            case StatusIntent.Invoiced:
                return 'Invoiced';
            case StatusIntent.Recused:
                return 'Recused';
            case StatusIntent.Canceled:
                return 'Canceled';
            case StatusIntent.EditSolicitation:
                return 'Edit Solicitation';
            case StatusIntent.ReviewCancelation:
                return 'Review Cancelation';
            default:
                return 'Other'
        }
    }
    else
    {
        switch (status) {
            case StatusIntent.UniparValidation:
                return 'Análisis Unipar';
            case StatusIntent.CustomerPendency:
                return 'Cliente en espera';
            case StatusIntent.OrderConfirmed:
                return 'Orden Confirmada';
            case StatusIntent.Arrived:
                return 'Llegó al patio';
            case StatusIntent.EntryWeight:
                return 'Entrada en la fábrica';
            case StatusIntent.LoadAndSealed:
                return 'Cargado';
            case StatusIntent.Invoiced:
                return 'Facturado';
            case StatusIntent.Recused:
                return 'Rechazado';
            case StatusIntent.Canceled:
                return 'Cancelado';
            case StatusIntent.EditSolicitation:
                return 'Análisis de cambios';
            case StatusIntent.ReviewCancelation:
                return 'Análisis de cancelación';
            default:
                return 'Outro'
        }
    }

}

export const getTranslateIdStatusIntent = (status: StatusIntent): string => {
    switch (status) {
        case StatusIntent.UniparValidation:
            return 'analise';
        case StatusIntent.CustomerPendency:
            return 'pendenteCliente';
        case StatusIntent.OrderConfirmed:
            return 'confirmado';
        case StatusIntent.Arrived:
            return 'chegou';
        case StatusIntent.EntryWeight:
            return 'entrou';
        case StatusIntent.LoadAndSealed:
            return 'carregando';
        case StatusIntent.Invoiced:
            return 'faturado';
        case StatusIntent.Canceled:
            return 'cancelado';
        case StatusIntent.Recused:
            return 'recusado';
        case StatusIntent.EditSolicitation:
            return 'analiseAlteracao';
        case StatusIntent.ReviewCancelation:
            return 'analiseCancelado';
        default:
            return 'Outro'
    }
}

export const getDescriptionStatusFinancial = (status: string, lng: string): string => {
    if(lng === 'pt')
    {
        switch (status) {
            case 'V':
                return 'Vencido';
            case 'C':
                return 'Compensado';
            case 'A':
                return 'À Vencer';
            default:
                return 'Outro'
        }
    }
    else if(lng === 'es')
    {
        switch (status) {
            case 'V':
                return 'Atrasado';
            case 'C':
                return 'Pagado';
            case 'A':
                return 'Pendiente';
            default:
                return 'Otro'
        }
    }
    else
    {
        switch (status) {
            case 'V':
                return 'Overdue';
            case 'C':
                return 'Paid Out';
            case 'A':
                return 'Pending';
            default:
                return 'Other'
        }
    }
}

export const getDescriptionPixKeyType = (status: number, language: string): string => {
    if(language === '' || language == null || language === undefined || language?.toLowerCase() === 'pt')
    {
        switch (status) {
            case PixKeyType.CNPJ:
                return 'CNPJ';
            case PixKeyType.Email:
                return 'Email';
            case PixKeyType.Telephone:
                return 'Telefone';
            default:
                return ''
        }
    }
    else if(language === 'es')
    {
        switch (status) {
            case PixKeyType.CNPJ:
                return 'CNPJ';
            case PixKeyType.Email:
                return 'Email';
            case PixKeyType.Telephone:
                return 'Teléfono';
            default:
                return ''
        }
    }
    else
    {
        switch (status) {
            case PixKeyType.CNPJ:
                return 'CNPJ';
            case PixKeyType.Email:
                return 'Email';
            case PixKeyType.Telephone:
                return 'Telephone';
            default:
                return ''
        }
    }
}

export const callCenterStatusStyle = [        
    {
        name: TicketStatusEnum.Pending,
        bgColor: '#FFF6C7',
        color: '#DFBC07',
    },
    {
        name: TicketStatusEnum.Served,
        color: '#2C99F7',
        bgColor: '#ECF6FF',
    },
    {
        name: TicketStatusEnum.Finished,
        bgColor: '#EEFFF2',
        color: '#30C10C',
    }
];

export const getTicketDescriptionStatus = (status: TicketStatusEnum) => {
    return t('callCenter.' + TicketStatusEnum[status]?.toLowerCase());
}