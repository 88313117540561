import moment from "moment";
import { Delivery, DeliveryUpdate } from "../types/newOrder";
import { StatusIntent } from "../types/orderIntent";
import callApi, { BaseResponseDto, BaseResponsePagedDto, callApiBlob, callApiFile, callApiFinancial } from "./baseRequest";
import { OrderIntentResponseDto } from "./orderHistoryService";

const routeIntent = 'order-intent';
const routeIntentV2 = 'v2/order-intent';

export interface RequestOrderIntent {
    shipmentDate?: Date,
    shipmentQuantity: number,
    orderMaterial: string,
    orderItem: string,
    orderNumber: string,
    intentItem?: Number,
    intentNumber?: Number,
    transporterCode?: string,
    transporterName?: string,
    veichleType: string,
    veichleTypeName: string,
    veichleTypeId: string,
    comment?: string,
    receiverCode?: string,
    isPVC?: boolean,
    materialCode?: string,
    materialDescription?: string,
    paymentCondition?: string,
    paymentConditionDescription?: string,
    customerCode: string,
    incoterms?: string,
    unitMeasurement: string,
    deposit?:string|undefined,
    commentItem?: string,
    truckId: string
}

export interface RequestOrderUpdateIntent extends RequestOrderIntent{
    status: StatusIntent,
    orderIntentGroupId?: string,
    id: string,
}

export function postOrderIntent(body: RequestOrderIntent[], language: string, email?: string): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: null,
        success: false
    };

    const response = callApiFinancial('POST', `${routeIntent}/create/${language}/${email ? `?emailBackoffice=${email}` : ''}`, body)
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
        }
        else {
            result.error = resp.error;
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }

        return result;
    })
    .catch(err => {
        result.data = err.data;
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function putOrderIntent(body: RequestOrderUpdateIntent[], language: string, email?: string): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: null,
        success: false
    };

    const response = callApiFinancial('PUT', `${routeIntent}/${language}/${email ? `?emailBackoffice=${email}` : ''}`, body)
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
        }
        else {
            result.error = resp.error;
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.data = err.data;
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;
        result.errors = err.errors;

        return result;
    })

    return response;
};

export interface ResponseOrderIntentShipment {
    id: string,
    orderIntentId?: string,
    orderIntentGroupId?: string,
    shipmentId?: string,
    materialCode?: string,
    materialDescription?: string,
    orderMaterial?: string,
    orderItem?: string,
    orderNumber?: string,
    intentNumber?: string,
    transporterCode?: string,
    transporterName?: string,
    shipmentDate?: Date,
    shipmentQuantity?: number,
    paymentCondition?: string,
    paymentConditionDescription?: string,
    incoterms?: string,
    unitValue?: string,
    discountValue?: string,
    freightValue?: string,
    subtotalValue?: string,
    totalValue?: number,
    status?: StatusIntent
    orderIntentHistory?: ResponseOrderIntentHistory,
    vehicleArrivalDate?: string,
    vehicleArrivalTime?: string,
    vehicleEntryDate?: string,
    vehicleEntryTime?: string,
    invoiceDate?: string,
    invoiceTime?: string,
    loadDate?: string,
    loadTime?: string,
    departure: string,
    departureDate: string,
    departureTime: string,
    checked: boolean,
    salesNumber: string,
    customerOrderNumber: string,
    deliveryDate?: Date
    nfNumber?: string,
    nfSeries?: string,
}

export interface ResponseOrderIntentShipmentDetail {
    orderMaterial: string,
    orderItem: string,
    orderNumber: string,
    intentNumber: string,
    transporterCode?: string,
    transporterName?: string,
    transportNumber?: string,
    shipmentNumber?: string,
    shipmentDate?: Date,
    shipmentQuantity: number,
    vehicleType?: string,
    vehicleName?: string,
    truckPlate?: string,
    nfNumber?: string,
    nfSeries?: string,
    receiverCode?: string,
    receiverName?: string,
    country?: string,
    city?: string,
    postalCode?: string,
    region?: string,
    address?: string,
    receiverAddress?: string,
    neighborhood?: string,
    center?: string,
    createdAt?: Date
}

export interface ResponseOrderIntentHistory {
    id: string,
    shipmentDate?: Date,
    shipmentQuantity: number,
    orderMaterial: string,
    orderItem: string,
    orderNumber: string,
    transporterCode?: string,
    transporterName?: string,
    veichleType: string,
    comment?: string,
    receiverCode?: string,
    receiverName?: string,
    isPVC?: boolean,
    materialCode?: string,
    materialDescription?: string,
    paymentCondition?: string,
    paymentConditionDescription?: string,
    customerCode: string,
    incoterms?: string,
    unitMeasurement: string,
    intentNumber: string,
    status: StatusIntent,
    pendingCustomerDate?: Date,
    updateConfirmed: boolean,
    integrated: boolean,
    recusedComment?: string,
    veichleTypeName: string,
    orderIntentId?: string,
}

export interface StatusQuatityResponse {
    uniparValidationQuantity: number,
    customerPendencyQuantity: number,
    orderConfirmedQuantity: number,
    arrivedQuantity: number,
    entryWeightQuantity: number,
    loadAndSealedQuantity: number,
    invoicedQuantity: number,
    invoicedOldQuantity: number,
    percentInvoiced: number,
    allQuantity: number,
}

export function getOrdersIntentsShipments(
    take: number,
    skip: number,
    search?: string,
    customerCode?: string,
    periodId?: string,
    materialCode?: string,
    status?: string[],
    month?: string,
    year?: string,
    sort?: string,
    language?: string,
    initialDate?: string,
    finalDate?: string): Promise<BaseResponsePagedDto<ResponseOrderIntentShipment[]>> {
    const result: BaseResponsePagedDto<ResponseOrderIntentShipment[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: [],
        success: false,
        currentTake: take,
        totalItems: 0,
        totalPages: 0
    };

    let url = `${routeIntent}/${customerCode}?take=${take}&skip=${skip}&search=${search}&materialCode=${materialCode}&periodid=${periodId}&month=${month}&year=${year}&useHistory=false&sort=${sort}&language=${language}&initialDate=${initialDate}&finalDate=${finalDate}`;

    status?.forEach(s => {
        if (s === '0') s = '';

        url = url + `&status=${s}`;
    })

    const response = callApi(
        'GET',
        url
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
            result.totalItems = resp?.totalItems;
            result.totalPages = resp?.totalPages;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getOnComingOrdersIntentsShipments(
    take: number,
    skip: number,
    customerCode?: string,
    materialCode?: string,
    month?: string,
    year?: string): Promise<BaseResponsePagedDto<ResponseOrderIntentShipment[]>> {
    const result: BaseResponsePagedDto<ResponseOrderIntentShipment[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',
        data: [],
        success: false,
        currentTake: take,
        totalItems: 0,
        totalPages: 0
    };

    const response = callApi(
        'GET',
        `${routeIntent}/onComing/${customerCode}?take=${take}&skip=${skip}&materialCode=${materialCode}&month=${month}&year=${year}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
            result.totalItems = resp?.totalItems;
            result.totalPages = resp?.totalPages;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getOrderIntentShipmentDetail(id: string):
    Promise<BaseResponseDto<ResponseOrderIntentShipmentDetail>> {
        const result: BaseResponseDto<ResponseOrderIntentShipmentDetail> = {
            error: '',
            errorPT: '',
            errorES: '',
            errorEN: '',
            data: {
                orderMaterial: '',
                orderItem: '',
                orderNumber: '',
                intentNumber: '',
                transporterCode: '',
                transporterName: '',
                transportNumber: '',
                shipmentNumber: '',
                shipmentDate: undefined,
                shipmentQuantity: 0,
                vehicleType: '',
                vehicleName: '',
                truckPlate: '',
                nfNumber: '',
                nfSeries: '',
                receiverCode: '',
                receiverName: '',
                country: '',
                city: '',
                postalCode: '',
                region: '',
                address: '',
                neighborhood: '',
                center: '',
                createdAt: undefined
            },
            success: false,
        };

    const response = callApi(
        'GET',
        `${routeIntent}/detail/${id}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export interface VehicleModel {
    TM: string,
    VeichleDescription: string
}

export interface TransporterModel  {
    FreightAgent: string,
    FreightAgentName: string
}

export interface TransporterVehicleModel {
    Transporters: TransporterModel[],
    Vehicles: VehicleModel[]
}

export interface DeliveryResponseModel {
    paymentCondition: string,
    incoterms: string,
    receiverCode: string,
    materialCode: string,
    materialDescription: string,
    pvc: boolean,
    orders: Delivery[]
}

export interface DeliveryResponseUpdateModel {
    paymentCondition: string,
    incoterms: string,
    receiverCode: string,
    materialCode: string,
    materialDescription: string,
    pvc: boolean,
    orders: DeliveryUpdate[]
}

export interface OrderCancellationRequestModel {
    orderIds: string[],
    reasonCancelation: string,
    issuerCode: String
}

export interface ReasonCancellationTypeResponseModel {
    id: string,
    name: string,
    description: string
}

export interface BulkVeichleTypeModel{
    id?: string,
    tm?:string
}

export interface BulkTransporteModel{
    name?: string,
    cod?: string

}

export interface BulkItemDeliveryModel {
    id?: string,
    materialCode?: string,
    materialDescription?: string,
    pvc?: boolean,
    quantity?: number,
    transp?: BulkTransporteModel
}
export interface BulkDeliveryResponseUpdateModel{
    id: string,
    truckId?: string,
    date?: Date,
    incoterms?: string,
    receiverCode?: string,
    veichleType?: BulkVeichleTypeModel,
    deposit?: string,
    error?:string,
    quantity?:number,
    weight?: number,
    orderNumber?: string,
    intentNumber?: string,
    orderItem?: string,
    item?: BulkItemDeliveryModel,
    salesNumber?: string,
    weightError: boolean,
    orderIntentGroupEntityId?: string,
    paymentCondition?: string,
    paymentConditionDescription?: string,
    customerCode?: string,
    intentItem?: string,
    sapIntegrationSuccess: boolean,
    weightMessage?: string
}

export function getFileOrders(
    search?: string,
    customerCode?: string,
    periodId?: string,
    materialCode?: string,
    status?: string[],
    month?: string,
    year?: string,
    ids?: string[],
    sort?: string,
    language?: string,
    initialDate?: string,
    finalDate?: string) : Promise<boolean>
{
    let url = `${routeIntent}/excel/${customerCode}?&search=${search}&materialCode=${materialCode}&periodid=${periodId}&month=${month}&year=${year}&sort=${sort}&language=${language}&initialDate=${initialDate}&finalDate=${finalDate}`;
    ids?.forEach(id => {
        url = url + `&ids=${id}`;
    })

    status?.forEach(s => {
        if (s === '0') s = '';

        url = url + `&status=${s}`;
    })

    const p = callApiBlob(
        'GET',
        url
    )
    .then((resp) => {
        const blob = new Blob([resp as BlobPart], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = `Orders${moment().format('DD-MM-yyyy')}.xlsx`;
        document.body.appendChild(element);
        element.click();

        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
};

export function getFileModel(materialCodes: string, incoterm: string, country: string, language: string, transportersVehicles: TransporterVehicleModel) : void
{
    callApiBlob(
        'POST',
        `${routeIntent}/file-model-import-orders/${incoterm}/${country}/${language}?materialCodes=${materialCodes}`,
        transportersVehicles
    )
    .then((resp) => {

        const blob = new Blob([resp as BlobPart], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = 'FileModelImportOrderIntent-' + incoterm.toUpperCase() + '.xlsx';
        document.body.appendChild(element);
        element.click();

    })
    .catch(err => {
        return;
    })

    return;
};

export function uploadFileOrderIntent(language: string, materialCodes: string, isPVC: boolean, incoterm: string, country: string, file: File, transportersVehicles: TransporterVehicleModel, issuer: string = '') : Promise<BaseResponseDto<Delivery[]>>
{
    const result: BaseResponseDto<Delivery[]> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: [],
        success: false,
        errors: []
    };

    const response = callApiFile(
        'POST',
        `${routeIntent}/import-file-order-intent/${language}/${isPVC}/${incoterm}/${country}?issuer=${issuer}&materialCodes=${materialCodes}`,
        file,
        transportersVehicles
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errors = resp?.data;
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
            result.data = resp?.data;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.data = err?.data;
        result.errors = err?.data;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })


    return response;
};

export function getStatusQuantityTon(customerCode: string, materialCode?: string): Promise<BaseResponseDto<StatusQuatityResponse>> {
    const result: BaseResponseDto<StatusQuatityResponse> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: {
            allQuantity: 0,
            arrivedQuantity: 0,
            customerPendencyQuantity: 0,
            entryWeightQuantity: 0,
            invoicedOldQuantity: 0,
            invoicedQuantity: 0,
            loadAndSealedQuantity: 0,
            orderConfirmedQuantity: 0,
            percentInvoiced: 0,
            uniparValidationQuantity: 0
        },
        success: false,
    };

    if (!materialCode) return Promise.resolve(result);

    const response = callApi(
        'GET',
        `${routeIntent}/status-quantity/${customerCode}/${materialCode}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getOrderToupdate(idGroup: string, issuer: string, idOrder?: string): Promise<BaseResponseDto<DeliveryResponseUpdateModel>> {
    const result: BaseResponseDto<DeliveryResponseUpdateModel> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: {
            incoterms: '',
            materialCode: '',
            materialDescription: '',
            orders: [],
            paymentCondition: '',
            receiverCode: '',
            pvc: false
        },
        success: false,
    };

    const response = callApi(
        'GET',
        `${routeIntent}/group-to-update/${idGroup}/${issuer}/${idOrder ? `?idOrder=${idOrder}` : ''}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getOrderTorebuy(idGroup: string, issuer: string, idOrder?: string): Promise<BaseResponseDto<DeliveryResponseModel>> {
    const result: BaseResponseDto<DeliveryResponseModel> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: {
            incoterms: '',
            materialCode: '',
            materialDescription: '',
            orders: [],
            paymentCondition: '',
            receiverCode: '',
            pvc: false
        },
        success: false,
    };

    const response = callApi(
        'GET',
        `${routeIntent}/group-to-rebuy/${idGroup}/${issuer}/${idOrder ? `?idOrder=${idOrder}` : ''}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getReasonCancellationType(language: string): Promise<BaseResponseDto<ReasonCancellationTypeResponseModel[]>> {
    const result: BaseResponseDto<ReasonCancellationTypeResponseModel[]> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: [],
        success: false,
    };

    const response = callApi(
        'GET',
        `${routeIntent}/reasons-cancellation-type/${language}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp.error;
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function cancelOrderIntent(request: OrderCancellationRequestModel): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        data: {},
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        success: false
    };

    const response = callApiFinancial('PUT', `${routeIntent}/cancel-orders`, request)
    .then(resp => {
        result.success = true;
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getProgramming(issuer: string, materialCode: string, month: string, year: string): Promise<BaseResponseDto<string[]>> {
    const result: BaseResponseDto<string[]> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: [],
        success: false,
    };

    const response = callApi(
        'GET',
        `${routeIntent}/list-shipment-dates/${issuer}?year=${year}&month=${month}&materialCode=${materialCode}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp.error;
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;

        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getListOrderOfProgramming(issuer: string, materialCode: string, date: string): Promise<BaseResponseDto<OrderIntentResponseDto[]>> {
    const result: BaseResponseDto<OrderIntentResponseDto[]> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: [],
        success: false,
    };

    const response = callApi(
        'GET',
        `${routeIntent}/list-shipments/${issuer}?shipmentDate=${date}&materialCode=${materialCode}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp.error;
            result.errorPT = resp.errorPT;
            result.errorEN = resp.errorEN;
            result.errorES = resp.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function getOrderToBulkUpdate(issuer: string, ids?: string[]): Promise<BaseResponseDto<BulkDeliveryResponseUpdateModel[]>> {
    const result: BaseResponseDto<BulkDeliveryResponseUpdateModel[]> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        data: []//{
            //id: '',
            // date: new Date(),
            // incoterms: '',
            // receiverCode: '',
            // veichleType: {},
            // deposit: '',
            // error: '',
            // quantity: 0,
            // weight: 0,
            // orderNumber: '',
            // intentNumber: '',
            // item: {}
        //}
        ,
        success: false,
    };

    const response = callApi(
        'POST',
        `${routeIntent}/bulk-group-list-to-update/${issuer}`, ids
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;

        return result;
    })

    return response;
};

export function putOrderBulkUpdate(language: string, email: string, orders: RequestOrderUpdateIntent[]): Promise<BaseResponseDto<OrderIntentResponseDto[]>> {
    const result: BaseResponseDto<OrderIntentResponseDto[]> = {
        error: '',
        errorEN:'',
        errorES:'',
        errorPT:'',
        errors: [],
        data: [],
        success: false,
    };

    const response = callApiFinancial(
        'PUT',
        `v2/${routeIntent}/bulk-update/${language}`, orders
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
            result.errors = resp?.errors;
        }
        else {
            result.error = resp?.error;
            result.errorPT = resp?.errorPT;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
            result.errors = resp?.errors;
            result.data = resp?.data;
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;
        result.errorPT = err.errorPT;
        result.errorEN = err.errorEN;
        result.errorES = err.errorES;
        result.errors = err.errors;
        result.data = err.data;

        return result;
    })

    return response;
};