import '../styles/NewOrder.scss';
import newOrder from '../assets/icons/new-order-uni.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tuckLocation from '../assets/icons/ic-truck.svg';
import weightTon from '../assets/icons/new-ton.png';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import { faFileUpload, faInfoCircle, faMinus, faPlus, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays, faCalendarXmark, faCheckCircle, faTrashAlt, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import truckLocation from '../assets/icons/ic-truck2.svg';
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { Accordion, AccordionButton, Button, Form, ProgressBar, Spinner } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { v4 as uuidv4, v4 } from 'uuid';
import UniModal from '../components/UniModal/UniModal';
import { useNavigate } from 'react-router-dom';
import { Material, getMaterialsByIssuer } from '../services/materialService';
import { useUserStore } from '../store/user';
import { CustomerData, getCustomerDataByIssuer } from '../services/customerService';
import { useTranslation } from 'react-i18next';
import { Delivery, DeliveryItem, DeliveryItemProduct, PaymentCond, Product, Receiver, Transporter, Veichle } from '../types/newOrder';
import UniNewOrderResume from '../components/UniNewOrderResume/UniNewOrderResume';
import { RequestOrderIntent, postOrderIntent, getFileModel, VehicleModel, TransporterModel, TransporterVehicleModel, uploadFileOrderIntent } from '../services/orderService';
import moment from 'moment';
import { useWindowSize } from '@uidotdev/usehooks';
import Multiselect from 'multiselect-react-dropdown';
import { productToSelect } from '../helpers/utils';

const NewOrder = () => {
    const [products, setProducts] = useState<Material[]>();
    const [customerData, setCustomerData] = useState<CustomerData>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedProducts, setSelectedProducts] = useState<Product[]>();
    const [selectedReceiver, setSelectedReceiver] = useState<Receiver>();
    const [selectedIncoterm, setSelectedIncoterm] = useState<string>();
    const [error, setError] = useState<string>();
    const [msgErrorDate, setErrorDate] = useState<string>('');
    const [msgErrorQuantity, setMsgErrorQuantity] = useState<string>('');
    const [msgErrorIntent, setErrorIntent] = useState<any[] | undefined>(undefined);
    const [msgErrorFileModel, setErrorFileModel] = useState<string>('');
    const [msgOrderIntentImport, setOrderIntentImport] = useState<string>('');
    const [msgErrorOrderIntentImport, setErrorOrderIntentImport] = useState<string[] | undefined>(undefined);
    const [showResume, setShowResume] = useState<boolean>(false);
    const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    const [showModalSucces, setShowModalSucces] = useState<boolean>(false);
    const [selectedPaymentCondition, setSelectedPaymentCondition] = useState<PaymentCond>();
    const [paymentConditions, setPaymentConditions] = useState<PaymentCond[]>();
    const [transporters, setTransporters] = useState<Transporter[]>();
    const [receivers, setReceivers] = useState<Receiver[]>();
    const [incoterms, setIncoterms] = useState<string[]>();
    const [deliveries, setDeliveries] = useState<Delivery[]>([]);
    const [veichles, setVeichles] = useState<Veichle[]>([]);
    const [multipleWeight, setMultipleWeight] = useState<any>({matiralCode:'', multipleWeight: 1});
    const [informedQuantity, setInformedQuantity] = useState<string>('');
    const [showNotMultiple, setShowNotMultiple] = useState<boolean>(false);
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
    const [obs, setObs] = useState<string>('');
    const [file, setFile] = useState<File>();
    const [fileError, setErrorFile] = useState<string>();
    const [showMassiveImport, setShowMassiveImport] = useState<boolean>(false);
    const [loadingMassiveImport, setLoadingMassiveImport] = useState<boolean>(false);
    const [onlyBuyPVC, setOnlyBuyPVC] = useState<boolean>(false);
    const [loadingFile, setLoadingFile] = useState<any>({ loading: false, percent: 20 });
    const navigate = useNavigate();
    const { selectedCustomer, loginData} = useUserStore();
    const { i18n, t } = useTranslation();
    const size = useWindowSize();
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [msgErrorsOnSap, setMsgErrorsOnSap ] = useState<boolean>(false);

    useEffect(() => {
        getMaterialsByIssuer(selectedCustomer.issuer)
            .then(r => {
                if (r.success) {
                    setProducts(r.materials);

                    setOnlyBuyPVC(!r.materials.some(p => !p.pvc))
                }
                else {
                    setError(r.error);
                }
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        document.querySelectorAll("input[data-input='true']")?.forEach(el => {
            el?.setAttribute('disabled', 'true');
        });
    }, [document.querySelectorAll("input[data-input='true']")]);

    useEffect(() => {
        let transporterList: Transporter[] = [];
        let receiverList: Receiver[] = [];
        let incotermsList: string[] = [];
        let paymentConditionsAdd: PaymentCond[] = [];
        let veichlesAdd: Veichle[] = [];

        if(selectedCustomer?.country?.toUpperCase() === 'AR')
        {
            incotermsList.push('CPT');
            incotermsList.push('FCA');
        }

        if (customerData?.defaultDataResponseDto?.length !== 0) {
            customerData?.defaultDataResponseDto?.forEach(d => {
                if (d.FreightAgent) {
                    let newTransporter: Transporter = {
                        cod: d.FreightAgent,
                        name: d.FreightAgentName,
                        incoterm: d.Incoterm?.toUpperCase()
                    }

                    if (!transporterList?.some(t => t.cod === newTransporter.cod)) {
                        transporterList.push(newTransporter);
                    }
                }

                let newIncoterm: string = d.Incoterm;

                if(selectedCustomer?.country?.toUpperCase() !== 'AR')
                {
                    if (!incotermsList?.some(i => i === newIncoterm)) {
                        incotermsList.push(newIncoterm);
                    }
                }

                var description = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition ?? '';

                if(description === '' || description === null || description === undefined)
                    description = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: description
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }

                customerData?.veichleTypeResponseDto?.forEach(v => {
                    if (d.FreightMaterialAgent) {
                        if (v.tms?.some(t => t.tm === d.FreightMaterialAgent?.trim()) ?? true) {
                            if (!veichlesAdd?.some(ve => ve.tm === v.tm && ve.incoterm === v.incoterm) || veichlesAdd.length === 0) {

                                let vehiclesDescriptions = v.veichleDescriptions?.filter(d => d.lang === i18n.language.toUpperCase());

                                if(vehiclesDescriptions.length === 0)
                                    vehiclesDescriptions = v.veichleDescriptions?.filter(d => d.lang === 'PT')

                                let newVeichle: Veichle = {
                                    id: v.id,
                                    descriptions: vehiclesDescriptions,
                                    incoterm: v.incoterm,
                                    materialCode: v.materialCode,
                                    tm: v.tm,
                                    maxValue: v.maxValue,
                                    minValue: v.minValue,
                                };

                                veichlesAdd.push(newVeichle);
                            }
                        }
                    }
                });
            });

        }
        else {
            customerData?.partnerResponseDtos?.forEach(d => {

                if (d.PartnerRole === 'AF') {
                    let newTransporter: Transporter = {
                        cod: d.Partner,
                        name: d.Name,
                        incoterm: 'AF'
                    }

                    transporterList.push(newTransporter);
                }
            });

            customerData?.salesDataResponseDto?.forEach(s => {
                let newIncoterm: string = s.Incoterms1;

                if (selectedCustomer?.country?.toUpperCase() !== 'AR' && !incotermsList?.some(i => i === newIncoterm)) {
                    if (newIncoterm === 'FOB' || newIncoterm === 'CEF' || newIncoterm === 'SFR') {
                        incotermsList.push(newIncoterm);
                    }
                }

                var descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === s.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? s.PaymentCondition ?? '';

                if(descriptionNew === '' || descriptionNew === null || descriptionNew === undefined)
                    descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === s.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? s.PaymentCondition ?? '';

                let paymentNew: PaymentCond = {
                    cod: s.PaymentCondition,
                    description: descriptionNew
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            });

            customerData?.veichleTypeResponseDto?.forEach(v => {
                let newVeichle: Veichle = {
                    id: v.id,
                    descriptions: v.veichleDescriptions,
                    incoterm: v.incoterm,
                    tm: v.tm,
                    maxValue: v.maxValue,
                    minValue: v.minValue,
                };

                veichlesAdd.push(newVeichle);
            });
        }

        setSelectedIncoterm(incotermsList?.[0]);
        setSelectedPaymentCondition(paymentConditionsAdd?.[0]);

        customerData?.partnerResponseDtos?.forEach(d => {
            if (d.PartnerRole === 'RM' && !receiverList?.some(i => i.cod === d.Partner)) {
                let newReceiver: Receiver = {
                    cod: d.Partner,
                    address: `${d.CNPJ ?? ''} - ${d.Name} - ${d.City ?? ''}`
                }

                receiverList.push(newReceiver);
            }
        });

        if (customerData?.partnerResponseDtos?.filter(pt => pt.PartnerRole === 'RM')?.[0] ?? false) {
            setSelectedReceiver({
                address: customerData?.partnerResponseDtos?.filter(pt => pt.PartnerRole === 'RM')?.[0]?.Name ?? '',
                cod: customerData?.partnerResponseDtos?.filter(pt => pt.PartnerRole === 'RM')?.[0]?.Partner ?? ''
            });
        }

        if (deliveries) {
            deliveries.forEach(d => {
                if (veichlesAdd?.filter(v => v.incoterm === selectedIncoterm)?.[0] ?? false) {
                    d.veichleType = veichlesAdd?.filter(v => v.incoterm === selectedIncoterm)?.[0]
                }

                let total = 0;
                d?.itens?.forEach(i => {
                    if (transporterList?.[0] ?? false) {
                        i.transp = {
                            cod: transporterList?.[0]?.cod,
                            name: transporterList?.[0]?.name
                        };
                    }

                    if ((i.products?.length ?? 1) > 1) {
                        recountDeliveryItem(i);
                    } else {
                        i.quantity = d?.veichleType?.minValue ?? i.quantity;
                        i?.products?.forEach(p => {
                            p.quantity = d?.veichleType?.minValue ?? p.quantity;
                        })
                    }

                    total += i.quantity;
                })

                d.weight = total;
            })
        }

        setPaymentConditions(paymentConditionsAdd);
        setTransporters(transporterList);
        setIncoterms(incotermsList);
        setReceivers(receiverList);
        setVeichles(veichlesAdd);
        setMultipleWeight(customerData?.multipleWeights ?? multipleWeight);

    }, [customerData?.defaultDataResponseDto, customerData?.partnerResponseDtos]);

    useEffect(() => {
        if (deliveries) {
            deliveries.forEach(d => {
                let total = 0;
                d?.itens?.forEach(i => {
                    if ((i.products?.length ?? 1) > 1) {
                        recountDeliveryItem(i);
                    } else {
                        i.quantity = i.quantity ?? d?.veichleType?.minValue;
                        i?.products?.forEach(p => {
                            p.quantity = p.quantity ?? d?.veichleType?.minValue;
                            
                        })
                    }
                    total += i.quantity;
                })

            d.weight = total;
            })
        }
    }, [fileUploaded])

    const onAddDelivery = (delivery: Delivery) => {
        setDeliveries([delivery, ...deliveries]);
    }

    const onRemoveProducts = (e: any, s: any) => {
        setLoadingProduct(true);

        const materialRemoved = selectedProducts?.filter(p => p.materialCode == s.id)?.[0] ?? null;

        if (materialRemoved) {
            const idxToRemove  = selectedProducts?.indexOf(materialRemoved) ?? -1;
            if (idxToRemove < 0) return;

            const productsTemp = selectedProducts;
            productsTemp?.splice(idxToRemove, 1);
            setSelectedProducts(productsTemp);
            removeProductItem(s.id);

            if (!productsTemp || productsTemp?.length <= 0) {
                setSelectedProducts(undefined);
                setLoadingProduct(false);
                return;
            }
        }

        getCustomerDataByIssuer(selectedCustomer.issuer, e.map((m: any) => m.id)?.join(',') ?? '', onlyBuyPVC ?? false)
            .then(r => {
                setCustomerData(r.data);
            })
            .catch(e => console.log(e))
            .finally(() => setLoadingProduct(false));
    }

    const onChangeProducts = (e: any, s: any) => {
        setLoadingProduct(true);

        const materialSelected = products?.filter(p => p.materialCode == s.id)?.[0] ?? null;

        if (materialSelected) {
            const productSelect: Product = {
                description: s.name,
                materialCode: materialSelected?.materialCode ?? '',
                pvc: materialSelected?.pvc ?? false,
                activitySector: materialSelected?.activitySector ?? '',
                productId: materialSelected.id
            };

            if (selectedProducts && onlyBuyPVC) setSelectedProducts([...selectedProducts, productSelect]);
            else setSelectedProducts([productSelect]);

            if (onlyBuyPVC) {
                addProductItem(productSelect);
            } else {
                changeProducts(productSelect);
            }
        }

        getCustomerDataByIssuer(selectedCustomer.issuer, e.map((m: any) => m.id)?.join(',') ?? '', onlyBuyPVC ?? false)
            .then(r => {
                setCustomerData(r.data);
            })
            .catch(e => console.log(e))
            .finally(() => setLoadingProduct(false));
    }

    const onChangeProduct = (event: ChangeEvent<HTMLSelectElement>) => {
        setLoadingProduct(true);

        setSelectedProduct({
            description: event.target?.selectedOptions[0].text,
            materialCode: event.target?.value,
            pvc: products?.filter(p => p.materialCode === event.target?.value)?.[0].pvc ?? false,
            activitySector: products?.filter(p => p.materialCode === event.target?.value)?.[0].activitySector ?? '',
            productId: products?.filter(p => p.materialCode === event.target?.value)?.[0].id ?? ''
        });

        getCustomerDataByIssuer(selectedCustomer.issuer, event.target?.value, products?.filter(p => p.materialCode === event.target?.value)?.[0]?.pvc ?? false)
            .then(r => {
                setCustomerData(r.data);
            })
            .catch(e => console.log(e))
            .finally(() => setLoadingProduct(false));
    }

    const removeProductItem = (materialCode: string) => {
        if (deliveries) {
            deliveries.forEach(d => {
                d.itens?.forEach(item => {
                    const productToRemove = item?.products?.filter(p => p.materialCode === materialCode)?.[0] ?? null;
                    if (productToRemove) {
                        const idxToRemove = item?.products?.indexOf(productToRemove) ?? -1;
                        item?.products?.splice(idxToRemove, 1);
                    }
                })
            });

            setDeliveries([...deliveries]);
        }
    }

    const addProductItem = (material: Product) => {
        if (deliveries) {
            deliveries.forEach(d => {
                d.itens?.forEach(item => {
                   item?.products?.push({
                    id: uuidv4(),
                    materialCode: material.materialCode,
                    materialDescription: material.description,
                    quantity: 1,
                    pvc: material.pvc ?? false
                   })
                })
            });

            setDeliveries([...deliveries]);
        }
    }

    const changeProducts = (material: Product) => {
        if (deliveries) {
            deliveries.forEach(d => {
                d.itens?.forEach(item => {
                    item?.products?.pop();
                    item?.products?.push({
                        id: uuidv4(),
                        materialCode: material.materialCode,
                        materialDescription: material.description,
                        quantity: veichles?.filter(v => v.incoterm === selectedIncoterm)?.[0]?.minValue ?? 1,
                        pvc: material.pvc ?? false
                    });
                })
            });

            setDeliveries([...deliveries]);
        }
    }

    const onChangeIncoterm = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedIncoterm(event.target?.value);

        let paymentConditionsAdd: PaymentCond[] = [];
        if (customerData?.defaultDataResponseDto?.length !== 0) {
            customerData?.defaultDataResponseDto.map(d => {

                var descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition ?? '';

                if(descriptionNew === '' || descriptionNew === null || descriptionNew === undefined)
                    descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: descriptionNew
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            })
        }
        else {

            customerData?.salesDataResponseDto.map(d => {

                var descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition  ?? '';

                if(descriptionNew === '' || descriptionNew === null || descriptionNew == undefined)
                    descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition  ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: descriptionNew
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            })
        }

        deliveries?.forEach(d => {
            let total = 0;
            d.veichleType = veichles?.filter(v => v.incoterm === event.target?.value)?.[0] ??
            {
                tm: '-',
                descriptions: [],
                incoterm: ''
            };
            d.date = undefined;

            d.itens?.forEach(i => {
                i.date = undefined;
                i.quantity = d?.veichleType?.minValue ?? i.quantity;
                total += i.quantity;

                if (event.target?.value === 'CEF') {
                    i.transp = {
                        cod: '0000202478',
                        name: 'UNIPAR CARBOCLORO S.A.'
                    }
                }

            });

            d.weight = total;
        })

        setSelectedPaymentCondition(paymentConditionsAdd?.[0]);
        setPaymentConditions(paymentConditionsAdd);
    }

    const onChangeReceiver = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedReceiver({
            address: event.target?.selectedOptions[0].text,
            cod: event.target?.value
        });
    }

    const onChangePayment = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedPaymentCondition({
            description: event.target?.selectedOptions[0].text,
            cod: event.target?.value
        });
    }

    //TODO: MOVE TO HELPER
    const getNextUtilDate = (date: Date) => {
        const initialDate = new Date(date);
        while (initialDate.toDateString() === date.toDateString() || date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    //TODO: MOVE TO HELPER
    const isValidDate = (e: any): boolean => {
        const date = new Date(e);
        if (!date) return false;

        if (date.toDateString() === new Date().toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidEqualDate'));
            return false;
        }

        if (date < new Date()) {
            setErrorDate(t('newOrderScreen.returnValidPassedDate'));
            return false;
        }

        if ((date.getDay() === 0 || date.getDay() === 6) && !!selectedProduct?.pvc && selectedIncoterm === 'FOB') {
            setErrorDate(t('newOrderScreen.returnValidEndofWeek'));
            return false;
        }

        const dateNow = new Date();
        if ((dateNow.getHours() >= 17 || dateNow.getDay() === 0 || dateNow.getDay() === 6) && date.toDateString() === getNextUtilDate(dateNow).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter17Hour'));
            return false;
        }

        const dateNow4 = new Date();
        if (selectedCustomer.enabledOrderAfterHour !==true && dateNow.getHours() >= 11 && date.toDateString() === getNextUtilDate(dateNow4).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter11Hour'));
            return false;
        }

        const dateNow2 = new Date();
        const dateNowT = new Date();

        var date1 = new Date(date.toDateString());
        var date2 = new Date(dateNowT.toDateString());

        var diffTime = date1.getTime() - date2.getTime();
        var diffDays = diffTime / (1000 * 3600 * 24);

        if (selectedCustomer.enabledOrderAfterHour !==true && dateNow2.getDay() === 5 && dateNow2.getHours() >= 11 && (date.getDay() === 0 || date.getDay() === 6) && diffDays < 7) {
            setErrorDate(t('newOrderScreen.returnValidAfter11FridayHour'));
            return false;
        }

        return true;
    }

    const isValidQuantities = (delivery: Delivery): boolean => {
        return !delivery?.itens?.some(i => i.quantity < delivery.veichleType.minValue) &&
            !delivery?.itens?.some(i => i.quantity > delivery.veichleType.maxValue)
    }

    const onChangeDate = (e: any, id: string) => {

        if (!isValidDate(e)) return;

        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            delivery.date = e;

            delivery.itens?.forEach(i => {
                i.date = delivery.date
            });

            delivery.error = '';

            setDeliveries([...deliveries]);
        }
    }

    const onChangeVeichle = (e: ChangeEvent<HTMLSelectElement>, id: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            const element = veichles?.filter(v => v.id === e.target?.selectedOptions[0].value && v.incoterm === selectedIncoterm && v.descriptions.some(d => d.description === e.target?.selectedOptions[0].text))?.[0] ??
                veichles?.filter(v => v.id === e.target?.selectedOptions[0].value && v.incoterm === selectedIncoterm)?.[0];

            delivery.veichleType = {
                id: e.target?.selectedOptions[0].value ?? '-',
                tm: element?.tm ?? '-',
                incoterm: element?.incoterm ?? 'CEF',
                minValue: element?.minValue ?? 0,
                maxValue: element?.maxValue ?? 0,
                descriptions: element?.descriptions ?? [],
            }

            let total = 0;
            delivery?.itens?.forEach(i => {
                if ((i.products?.length ?? 1) > 1) {
                    recountDeliveryItem(i);
                } else {
                    i.quantity = delivery?.veichleType?.minValue ?? i.quantity;
                    i?.products?.forEach(p => {
                        p.quantity = delivery?.veichleType?.minValue ?? p.quantity;
                    })
                }

                i.error = '';
                total += i.quantity;
            });

            delivery.weight = total;

            setDeliveries([...deliveries]);
        }
    }

    const onChangeTransporter = (e: ChangeEvent<HTMLSelectElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.transp = {
                    name: e.target?.selectedOptions[0].text,
                    cod: e.target?.value
                }
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeQuantityItem = (e: ChangeEvent<FormControlElement>, id: string, idItem: string, idProduct?: string) => {
        const idx = deliveries.findIndex(d => d.id === id);

        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                let product = item?.products?.filter(i => i.productId === idProduct)[0];
                if (product) {
                    product.quantity = (Number(e?.target?.value) ?? 0);
                }

                let total = 0;

                item?.products?.map(t => t.quantity)?.forEach(n => {
                    if(n) total += n;
                });

                item.quantity = (total ?? 0);
                item.error = '';
            }

            if (delivery.weight !== undefined) {
                let total = 0;

                delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                    total += n;
                });

                delivery.weight = total;
            }

            setDeliveries([...deliveries]);
        }
    }

    const onBlurQuantityItem = (e: FocusEvent<FormControlElement, Element>, id: string, idItem: string, idProduct?: string, productMultiple?: any) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];
            let change = false;
            let countOfProducts = item?.products?.length ?? 0;

            if (item) {
                let product = item?.products?.filter(i => i.productId === idProduct)[0];

                if (product) {
                    if (Number(e?.target?.value) <= delivery.veichleType.minValue && countOfProducts <= 1) {
                        product.quantity = delivery.veichleType.minValue;
                        item.error = '';
                        change = true;
                    }
                    else if (Number(e?.target?.value) >= delivery.veichleType.maxValue && countOfProducts <= 1) {
                        product.quantity = delivery.veichleType.maxValue;
                        item.error = '';
                        change = true;
                    } else {
                        if (productMultiple != 1 && Number(e?.target?.value) % productMultiple > 0) {
                            change = true;
                            item.quantity = delivery.veichleType.minValue;
                        setInformedQuantity(e?.target?.value);
                            setShowNotMultiple(true);
                        }
                    }
                }

                if (!change) return;

                let total = 0;

                item?.products?.map(t => t.quantity)?.forEach(n => {
                    if(n) total += n;
                });

                item.quantity = (total ?? 0);
                item.error = '';
            }

            if (!change) return;

            if (delivery.weight !== undefined) {
                let total = 0;

                delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                    total += n;
                });

                delivery.weight = total;
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderNumber = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.orderNumber = e?.target?.value ?? ''
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeMaterialNumber = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.materialNumber = e?.target?.value ?? '';
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderItem = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.orderItem = e?.target?.value ?? '';
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderItemComment = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.comment = e?.target?.value ?? '';
            }

            setDeliveries([...deliveries]);
        }
    }

    const recountDeliveryWeight = (delivery: Delivery) => {
        if (delivery.weight !== undefined) {
            let total = 0;

            delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                total += n;
            });
            delivery.weight = total;
        }
    }

    const recountDeliveryItem = (item: DeliveryItem) => {
        let total = 0;

        item?.products?.map(p => p.quantity)?.forEach(n => {
            total += n;
        });

        item.quantity = total;
    }

    const removeDelivery = (id: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1)
            setDeliveries(deliveries?.filter(d => d.id !== id));
        else return
    }

    const removeDeliveryItem = (id: string, idItem: string, idProduct: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];

            if (delivery.itens) {
                const item = delivery.itens?.filter(i => i.id === idItem)?.[0];
                if (item && item.products) {
                    if (item.products.length > 1) {
                        item.products = item.products?.filter(p => p.id !== idProduct);
                        recountDeliveryItem(item);
                    } else {
                        delivery.itens = delivery.itens?.filter(i => i.id !== idItem);
                        changeOnlyCount(id, '');
                    }
                } else {
                    delivery.itens = delivery.itens?.filter(i => i.id !== idItem);
                    changeOnlyCount(id, '');
                }
            }

            recountDeliveryWeight(delivery);
            setDeliveries([...deliveries]);
        }
    }

    const changeOnlyCount = (id: string, count: string) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery) return;

        if (count === 'plus') {
            delivery.quantity += 1;
        }
        else if ((delivery.quantity - 1) >= 0) {
            delivery.quantity -= 1;
        }

        recountDeliveryWeight(delivery);
        setDeliveries([...deliveries]);
    }

    const changeCount = (id: string, count: string) => {
        if (selectedProducts == undefined) return;

        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery) return;

        if (count === 'plus') {
            delivery.quantity += 1;

            let countSelectedProducts = selectedProducts.length;
            let weightItemTotal = 0;
            let multiple = 1;

            const itensProducts: DeliveryItemProduct[] = selectedProducts.map(sp => {
                let quantity = 1;

                if (countSelectedProducts > 1) {
                    multiple = multipleWeight?.filter((m: any) => m.materialCode === sp.materialCode)?.[0]?.multipleWeight ?? 1;
                    quantity =  multiple ?? 1;
                } else  {
                    quantity = veichles?.filter(v => v.incoterm === selectedIncoterm)?.[0]?.minValue ?? 0
                }

                weightItemTotal += quantity;
                return {
                    id: uuidv4(),
                    materialCode: sp.materialCode,
                    materialDescription: sp.description,
                    quantity: quantity,
                    multiple: multiple,
                    activitySector: sp.activitySector,
                    pvc: sp.pvc ?? false,
                    productId: sp.productId

                }
            })

            if (delivery.itens) {
                delivery.itens?.push({
                    id: uuidv4(),
                    materialNumber: '',
                    orderItem: '',
                    orderNumber: '',
                    quantity: weightItemTotal ?? delivery?.veichleType?.minValue ?? 0,
                    date: delivery.date,
                    transp: transporters?.[0] ? {
                        cod: transporters?.[0]?.cod,
                        name: transporters?.[0]?.name,
                    } : undefined,
                    products: itensProducts
                })
            }
            else {
                delivery.itens = [{
                    id: uuidv4(),
                    materialNumber: '',
                    orderItem: '',
                    orderNumber: '',
                    quantity: weightItemTotal ?? delivery?.veichleType?.minValue ?? 0,
                    date: delivery.date,
                    transp: transporters?.[0] ? {
                        cod: transporters?.[0]?.cod,
                        name: transporters?.[0]?.name,
                    } : undefined,
                    products: itensProducts
                }];
            }
        }
        else if ((delivery.quantity - 1) >= 1) {
            delivery.quantity -= 1;
            delivery.itens?.pop();
        }

        recountDeliveryWeight(delivery);
        setDeliveries([...deliveries]);
    }

    const changeDeposit = (id: string, value: string|undefined) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery || !value) return;

        delivery.deposit = value;

        setDeliveries([...deliveries]);
    }

    const goToResume = () => {
        if (deliveries) {
            let anyWithoutDate = false;
            let anyWithoutTransporter = false;
            let anyWithoutVeichleType = false;
            let anyWithoutQuantity = false;
            let anyWithoutDeposit = false;

            anyWithoutVeichleType = deliveries?.some(d => d.veichleType.tm === '-') ?? false;
            anyWithoutDate = deliveries?.some(d => d.date === undefined) ?? false;
            anyWithoutDeposit = ((selectedCustomer?.country === 'AR' ?? false) && (deliveries?.some(d => !d.deposit || d.deposit === '-') ?? true));

            for (let d of deliveries) {
                if (!isValidQuantities(d)) {
                    setMsgErrorQuantity(t('messageErrorQuantity'));
                    return;
                }

                anyWithoutTransporter = d.itens?.some(i => selectedIncoterm === 'FOB' && i.transp === undefined) ?? false;
                anyWithoutQuantity = d.itens?.some(i => i.quantity === 0) ?? false;

                if (anyWithoutDate || anyWithoutTransporter || anyWithoutVeichleType || anyWithoutQuantity || anyWithoutDeposit) {
                    setShowErrorForm(true);
                    return;
                }
            }

            setShowErrorForm(false);
            setShowResume(true);
        }
    }

    const sendOrders = async () => {
        let request: RequestOrderIntent[] = [];
        let intentNumber: number = 1;

        for (let d of deliveries) {

            if (!isValidDate(d.date)) return;
            if (!isValidQuantities(d)) {
                setMsgErrorQuantity(t('messageErrorQuantity'));
                return;
            }

            d?.itens?.forEach((i, idxItem) => {
                if (selectedIncoterm === 'CEF') {
                    i.transp = {
                        cod: '0000202478',
                        name: 'UNIPAR CARBOCLORO S.A.'
                    }
                }
                let truckId = uuidv4()
                d.truckId = truckId;

                const activitiesSector = Array.from(new Set(i?.products?.map(p => p.activitySector)));

                activitiesSector?.forEach((sector) =>{
                        i?.products?.filter(p => p.activitySector === sector).forEach((p, idxP) => {
                            let item: RequestOrderIntent = {
                                shipmentDate: d.date,
                                shipmentQuantity: p.quantity ?? i.quantity,
                                orderMaterial: i.materialNumber,
                                orderItem: i.orderItem,
                                orderNumber: i.orderNumber,
                                transporterCode: i.transp?.cod,
                                transporterName: i.transp?.name,
                                veichleType: d.veichleType?.tm,
                                veichleTypeName: d.veichleType?.descriptions?.[0]?.description ?? '',
                                comment: obs,
                                receiverCode: selectedReceiver?.cod,
                                isPVC: products?.filter(prd => prd.materialCode === p.materialCode)?.[0].pvc ?? false,
                                materialCode: p?.materialCode,
                                materialDescription: p?.materialDescription,
                                paymentCondition: selectedPaymentCondition?.cod,
                                paymentConditionDescription: selectedPaymentCondition?.description,
                                customerCode: selectedCustomer.issuer,
                                incoterms: selectedIncoterm,
                                unitMeasurement: 'TO',
                                veichleTypeId: d.veichleType?.id,
                                deposit: d.deposit ?? undefined,
                                intentItem: (idxP + 1),
                                intentNumber: intentNumber,
                                truckId: truckId,
                                commentItem: i.comment
                            };

                            request.push(item);
                        });

                        intentNumber ++;
            });

            })
        }

        setLoadingCreate(true);
        const result = await postOrderIntent(request, i18n.language.toUpperCase(), loginData?.email ?? '');
        setLoadingCreate(false);
        if (!result.success) {
            if (result.data) {
                if (result.error === "[Create Order v2] Some order was not created due a error on SAP."){
                    let trucksId: string[] = result.data;

                    const orderWithError = deliveries.filter(d => !trucksId.includes(d.truckId ?? ''));

                    setDeliveries(orderWithError);
                    setMsgErrorsOnSap(true);

                }
                else
                    setErrorIntent(result.data);
            }
            else {
                var arrError = [];
                var error = result.error ?? '';

                if(i18n.language.toUpperCase() === 'PT')
                    error = result.errorPT ?? '';
                if(i18n.language.toUpperCase() === 'EN')
                    error = result.errorEN ?? '';
                if(i18n.language.toUpperCase() === 'ES')
                    error = result.errorES ?? '';

                arrError.push(error);
                setErrorIntent(arrError);
            }
        } else {
            setShowModalSucces(true);
        }
    }

    const downloadFileModel = (incoterm: string) => {
        if(selectedProduct || selectedProducts)
        {
            const vehicles: VehicleModel[] = [];
            const transporters: TransporterModel[] = [];

            veichles?.filter(v => v.incoterm === selectedIncoterm)?.forEach(v => {
                let descs = v.descriptions?.filter(d => d.lang === i18n.language.toUpperCase());

                if(descs.length === 0)
                    descs = v.descriptions?.filter(d => d.lang === 'PT');

                descs.forEach(d => {
                    let vehicle: VehicleModel = {
                        TM: v.tm,
                        VeichleDescription: d.description
                    };

                    if (!vehicles?.some(vei => vei.VeichleDescription == d.description)) {
                        vehicles.push(vehicle);
                    }
                });
            });

            if (incoterm === 'FOB')
            {
                customerData?.defaultDataResponseDto?.forEach(d =>
                {
                    if (d.Incoterm === selectedIncoterm) {
                        let transporter: TransporterModel = {
                            FreightAgent: d.FreightAgent,
                            FreightAgentName: d.FreightAgentName
                        }

                        transporters.push(transporter);
                    }

                });
            }

            let transportersVehicles: TransporterVehicleModel = {
                Transporters: transporters,
                Vehicles: vehicles,
            };

            if(selectedProducts)
                getFileModel(selectedProducts?.map(i=>i.materialCode).join(',') ?? '', incoterm, selectedCustomer?.country ?? 'BR', i18n.language.toUpperCase(), transportersVehicles);
            else if(selectedProduct)
                getFileModel(selectedProduct?.materialCode ?? '', incoterm, selectedCustomer?.country ?? 'BR', i18n.language.toUpperCase(), transportersVehicles);

        }
        else {
            setErrorFileModel(t('newOrderScreen.returnErrorSelectedProduct'));
        }
    }

    const handleUploadFileOrderIntent = (): void => {

        if (!selectedProduct && !selectedProducts) {
            setErrorFileModel(t('newOrderScreen.returnErrorSelectedProduct'));
            return;
        }

        if (!selectedIncoterm) {
            setErrorFileModel(t('newOrderScreen.returnErrorSelectedMethodDelivery'));
            return;
        }

        if ((selectedProduct || selectedProducts) && file && selectedIncoterm) {
            setLoadingMassiveImport(true);

            var materialCodes = '';

            if(selectedProduct)
                materialCodes = selectedProduct?.materialCode ?? '';
            else if(selectedProducts)
                materialCodes = selectedProducts?.map(i=>i.materialCode).join(',') ?? '';

            if(selectedProduct || selectedProducts)
                {
                    const vehicles: VehicleModel[] = [];
                    const transporters: TransporterModel[] = [];

                    veichles?.filter(v => v.incoterm === selectedIncoterm)?.forEach(v => {
                        let descs = v.descriptions?.filter(d => d.lang === i18n.language.toUpperCase());

                        if(descs.length === 0)
                            descs = v.descriptions?.filter(d => d.lang === 'PT');

                        descs.forEach(d => {
                            let vehicle: VehicleModel = {
                                TM: v.tm,
                                VeichleDescription: d.description
                            };

                            if (!vehicles?.some(vei => vei.VeichleDescription == d.description)) {
                                vehicles.push(vehicle);
                            }
                        });
                    });

                    if (selectedIncoterm === 'FOB')
                    {
                        customerData?.defaultDataResponseDto?.forEach(d =>
                        {
                            if (d.Incoterm === selectedIncoterm) {
                                let transporter: TransporterModel = {
                                    FreightAgent: d.FreightAgent,
                                    FreightAgentName: d.FreightAgentName
                                }

                                transporters.push(transporter);
                            }

                        });
                    }

                    let transportersVehicles: TransporterVehicleModel = {
                        Transporters: transporters,
                        Vehicles: vehicles,
                    };

            uploadFileOrderIntent(i18n.language.toUpperCase(), materialCodes, onlyBuyPVC, selectedIncoterm, selectedCustomer.country, file, transportersVehicles, selectedCustomer.issuer)
                .then(response => {
                    if (response.success) {
                        setOrderIntentImport(t('newOrderScreen.returnOKImportFile'));
                        setShowMassiveImport(false);
                        populateDataByExcel(response.data);
                    } else {
                        setErrorOrderIntentImport(response?.errors ?? undefined);
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setFile(undefined);
                    setLoadingMassiveImport(false);
                })
            }
        }
        else {
            setErrorFileModel(t('newOrderScreen.returnErrorSelectedProduct'));
        }
    }

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setErrorFile('');
        const files = Array.from(e?.target?.files ?? []);

        const extension = files[0]?.name?.split('.')?.[files[0]?.name?.split('.')?.length - 1]?.toLowerCase() ?? '';
        if (extension !== 'csv' && extension !== 'xlsx' && extension !== 'xls') {
            setErrorFile(t('newOrderScreen.returnErrorExtensionFiles'));
            return;
        }
        setFile(files[0]);
    }

    const populateDataByExcel = (deliveriesEx: Delivery[]) => {

        let deliveriesTemp: Delivery[] = [];

        deliveriesEx?.forEach(d => {
            d.deposit = d.deposit?.toUpperCase();
            if (veichles?.filter(v => v.id == d.veichleType.id)?.[0]) {
                const veichleEx = veichles?.filter(v => v.id == d.veichleType.id && v.incoterm === selectedIncoterm)?.[0];
                d.veichleType = {
                    descriptions: veichleEx.descriptions,
                    id: veichleEx.id,
                    incoterm: veichleEx.incoterm,
                    maxValue: veichleEx.maxValue,
                    minValue: veichleEx.minValue,
                    tm: veichleEx.tm
                }
            } else {
                d?.veichleType?.descriptions.forEach(descEx => {
                    const veichleEx = veichles?.filter(v => v.descriptions.some(c => c.description === descEx.description && v.incoterm === selectedIncoterm))?.[0];
                    if (veichleEx) {
                        d.veichleType = {
                            descriptions: veichleEx.descriptions,
                            id: veichleEx.id,
                            incoterm: veichleEx.incoterm,
                            maxValue: veichleEx.maxValue,
                            minValue: veichleEx.minValue,
                            tm: veichleEx.tm
                        }
                    }
                })
            }

            if ((selectedCustomer.country === 'BR' || !selectedCustomer.country))
            {
                d.itens?.forEach(i => {
                    if (transporters?.filter(t => t.cod === i.transp?.cod)?.[0]) {
                        const transpEx = transporters?.filter(t => t.cod === i.transp?.cod)?.[0];
                        i.transp = {
                            cod: transpEx.cod,
                            name: transpEx.name
                        }
                    } else if (transporters?.[0]){
                        i.transp = {
                            cod: transporters?.[0]?.cod,
                            name: transporters?.[0]?.name
                        }
                    }
                })
            }

            deliveriesTemp.push(d);
        })

        setDeliveries([...deliveriesTemp]);
        setFileUploaded(!fileUploaded);
    }

    return (
        <>
            <div className='d-flex new-order-body'>
                <div className='new-order-content'>
                    <div className='new-order-content-header'>
                        <img src={newOrder} />
                        <p>{t('newOrderScreen.newOrderTitle')}</p>
                    </div>
                    {
                        !showResume ? (
                            <>
                                <div className='new-order-content-from'>
                                    <div>
                                        <p className='form-title'>
                                            {t('newOrderScreen.labelProductInformation')}
                                        </p>

                                        <div className='product-info'>
                                            <div className='d-flex flex-column'>
                                                <Form.Label htmlFor='prd'>{t('newOrderScreen.labelProduct') + ' *'}</Form.Label>
                                                <Multiselect
                                                    options={productToSelect(products, i18n)}
                                                    onSelect={(e: any, s: any) => onChangeProducts(e, s)}
                                                    onRemove={(e: any, s: any) => onRemoveProducts(e, s)}
                                                    id='prd'
                                                    displayValue='name'
                                                    closeOnSelect={true}
                                                    disable={loadingProduct}
                                                    emptyRecordMsg='Carregando...'
                                                    placeholder={t('newOrderScreen.labelSelect')}
                                                    hidePlaceholder
                                                    showCheckbox
                                                    customCloseIcon={!onlyBuyPVC ? <span></span> : undefined}
                                                    showArrow
                                                    singleSelect={!onlyBuyPVC}
                                                />
                                            </div>

                                            {/* <div>
                                                <UniInput label={t('newOrderScreen.labelProduct') + ' *'} id='prd' value={selectedProduct?.materialCode} isSelect type='select' placeholder={t('newOrderScreen.labelSelect')} defaultValue={'0'} onChange={(e) => onChangeProduct(e)}>
                                                    <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                                    {products?.map((p, idx) => {
                                                        return (<option key={idx} value={p.materialCode}>{p.descriptions?.filter(d => d.language === i18n.language.toUpperCase())?.[0]?.description ?? p.descriptions?.filter(d => d.language === 'PT')?.[0]?.description ?? ''}</option>)
                                                    })}
                                                </UniInput>
                                            </div> */}

                                            <div className='new-order-snacker'>
                                                <div>
                                                    <img id="imgWeight" src={weightTon} alt='icone tonelada' style={{ height: 40 }} />
                                                    <div className='new-order-snacker-text'>
                                                        <span>{t('newOrderScreen.labelTotalVolume')}</span>
                                                        <p>{
                                                            deliveries?.length ?
                                                                deliveries?.map(d => d.weight ?? 0).reduce((acc, cur) => acc + cur, 0) + ' t'
                                                                : '-'
                                                        }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src={tuckLocation} alt='icone caminhao' />
                                                    <div className='new-order-snacker-text'>
                                                        <span>{t('newOrderScreen.labelOrder')}</span>
                                                        <p>
                                                            {
                                                                deliveries?.length ?
                                                                    deliveries?.map(d => d.itens?.length ?? 0).reduce((acc, cur) => acc + cur, 0)
                                                                    : '-'
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='divider'></div>
                                    </div>

                                    <div>
                                        <p className='form-title'>
                                            {t('newOrderScreen.labelOrderDetail')}
                                        </p>
                                        <div className='new-order-selects'>
                                            <div className='select-rm'>
                                                <UniInput
                                                    label={t('newOrderScreen.labelDeliveryPlace')}
                                                    id='lcl'
                                                    isSelect
                                                    type='select'
                                                    loading={loadingProduct}
                                                    value={selectedReceiver?.cod}
                                                    defaultValue={receivers?.[0]?.cod}
                                                    placeholder={t('newOrderScreen.labelSelect')}
                                                    onChange={(e) => onChangeReceiver(e)}
                                                    disabled={selectedProducts === undefined}
                                                >
                                                    <option disabled value={undefined}>{t('newOrderScreen.labelSelect')}</option>
                                                    {
                                                        receivers?.map((pt, idx) => {
                                                            return <option key={idx} value={pt.cod}>{pt.address}</option>
                                                        })
                                                    }
                                                </UniInput>
                                            </div>

                                            <div className='d-flex  flex-wrap'>
                                                <UniInput label={t('newOrderScreen.labelFreight')} id='frt' loading={loadingProduct} disabled={selectedProducts === undefined} isSelect type='select' defaultValue={incoterms?.[0]} value={selectedIncoterm} placeholder={t('newOrderScreen.labelSelect')} onChange={(e) => onChangeIncoterm(e)}>
                                                    <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                                    {
                                                        incoterms?.map((i, idx) => {
                                                            if (i === 'CEF' || i === 'FOB' || i === 'SFR') {
                                                                return (
                                                                    <option value={i} key={idx}>{i === 'CEF' ? t('newOrderScreen.labelCIFFile') : i === 'SFR' ? t('newOrderScreen.labelSFRFile') : t('newOrderScreen.labelFOBFile')}</option>
                                                                )
                                                            }
                                                            if (i === 'FCA' || i === 'CPT') {
                                                                return (
                                                                    <option value={i} key={idx}>{i === 'CPT' ? t('newOrderScreen.labelCPTFile') : t('newOrderScreen.labelFCAFile')}</option>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </UniInput>

                                                {
                                                    selectedCustomer.country != 'AR' && (
                                                        <UniInput label={t('newOrderScreen.labelPaymentConditions')} loading={loadingProduct} id='cond' disabled={selectedProducts === undefined} isSelect type='select' value={selectedPaymentCondition?.cod} defaultValue={paymentConditions?.[0]?.cod} placeholder={t('newOrderScreen.labelSelect')} onChange={(e) => onChangePayment(e)}>
                                                            <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                                            {
                                                                paymentConditions?.map((pt, idx) => {
                                                                    return <option key={idx} value={pt.cod}>
                                                                        {customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === pt.cod && i18n.language.toUpperCase() === p.Language)?.[0]?.Description ??
                                                                            customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === pt.cod && p.Language === 'PT')?.[0]?.Description ?? ''}
                                                                    </option>
                                                                })
                                                            }
                                                        </UniInput>
                                                    )
                                                }

                                            </div>
                                            {deliveries?.length > 0 && <p className='p-changes'>{selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelChangesInformationFOB'): t('newOrderScreen.labelChangesInformationCIF') }</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='deliveries'>
                                    <div className='d-flex justify-content-between align-items-center deliveries-header'>
                                        <div className='button-upload' onClick={() => {
                                            if (selectedProducts) {
                                                setShowMassiveImport(true)
                                            } else {
                                                setErrorFileModel(t('newOrderScreen.returnErrorSelectedProduct'))
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faFileUpload} />
                                            <p>{t('newOrderScreen.labelBulkOrder')}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column-reverse'>
                                        {
                                            deliveries?.map((delivery, idx) => {
                                                return (
                                                    <Accordion key={idx} defaultActiveKey={'0'}>
                                                        <Accordion.Item key={idx} eventKey={'0'}>
                                                            <div className='d-flex flex-column wrapper-delivery-form'>
                                                                {delivery.error && <p style={{ color: '#EA2027' }}>{delivery.error}</p>}

                                                                <div className='inputs-body d-flex gap-4 w-100'>
                                                                    <div className='dt-tp d-flex gap-4 w-100'>
                                                                        <div className={selectedCustomer?.country === 'AR' ? 'date-wrapper-ar': 'date-wrapper'}>
                                                                            <label htmlFor='date-picker' style={{ marginBottom: 8 }}>{selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelIncotermDateFOB') : t('newOrderScreen.labelIncotermDateCIF')} *</label>
                                                                            <div className={showErrorForm && delivery.date === undefined ? 'input date input-error' : 'input date'} style={{width: '150px'}}>
                                                                                <DatePicker
                                                                                    id='date-picker'
                                                                                    clearIcon={null}
                                                                                    calendarIcon={<FontAwesomeIcon icon={faCalendarDays} />}
                                                                                    dayPlaceholder=''
                                                                                    monthPlaceholder=''
                                                                                    yearPlaceholder=''
                                                                                    onChange={e => onChangeDate(e, delivery.id)}
                                                                                    value={delivery.date}
                                                                                    closeCalendar
                                                                                    minDetail='year'
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className={showErrorForm && delivery.veichleType.tm === '-' ? 'input input-error' : 'input'}>
                                                                            <UniInput
                                                                                id='vei'
                                                                                label={t('newOrderScreen.labelVehicleType') + ' *'}
                                                                                isSelect
                                                                                type='select'
                                                                                value={delivery.veichleType.id}
                                                                                placeholder={t('newOrderScreen.labelVehicleType')}
                                                                                defaultValue={'-'}
                                                                                onChange={(e) => onChangeVeichle(e, delivery.id)}
                                                                            >
                                                                                <option disabled value={'-'}>{t('newOrderScreen.labelSelect')}</option>
                                                                                {
                                                                                    veichles?.filter(v => v.incoterm === selectedIncoterm)?.map((v, idx) => (
                                                                                        <option key={idx} value={v.id}>{v.descriptions.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? v.descriptions.filter(d => d.lang === 'PT')?.[0]?.description ?? ''}</option>
                                                                                    ))
                                                                                }
                                                                            </UniInput>
                                                                        </div>

                                                                        {selectedCustomer?.country === 'AR' &&
                                                                            <div className={showErrorForm && ((selectedCustomer?.country === 'AR' ?? false) && ((selectedCustomer?.country === 'AR' ?? false) && (deliveries?.some(d => !d.deposit || d.deposit === '-') ?? true))) ? 'input input-error' : 'input'}>
                                                                                <UniInput
                                                                                    id='deposit'
                                                                                    label={t('newOrderScreen.labelDeposit') + ' *'}
                                                                                    isSelect
                                                                                    type='select'
                                                                                    value={delivery.deposit}
                                                                                    placeholder={t('newOrderScreen.labelDeposit')}
                                                                                    defaultValue={'-'}
                                                                                    onChange={(e) => {changeDeposit(delivery.id, e.target.value)}}
                                                                                >
                                                                                    <option value={'-'}>{t('newOrderScreen.labelSelect')}</option>
                                                                                    <option value='BAHIA BLANCA'>Bahia Blanca</option>
                                                                                    <option value='EZEIZA'>Ezeiza</option>
                                                                                </UniInput>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className={size?.width && size?.width < 700 ? 'qtd-counter d-flex w-100 gap-2' : 'qtd-counter d-flex gap-2 justify-content-between w-100'}>
                                                                        <div className='d-flex gap-4'>
                                                                            <div>
                                                                                <label htmlFor='counter'>{t('newOrderScreen.labelVehiclesQty')}</label>
                                                                                <div className='counter-delivery' id='counter'>
                                                                                    <div className='control' onClick={() => changeCount(delivery.id, '')}>
                                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                                    </div>
                                                                                    <div className='number'>{delivery.quantity?.toString()}</div>
                                                                                    <div className='control' onClick={() => changeCount(delivery.id, 'plus')}>
                                                                                        <FontAwesomeIcon icon={faPlus} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='input-weight input'>
                                                                                <UniInput id='weight' type='text' label={t('newOrderScreen.labelTotalQty')} readOnly placeholder={`${delivery.weight} t`} value={`${delivery.weight} t`} />
                                                                            </div>
                                                                        </div>

                                                                        <div className='icons-acc align-items-center d-flex'>
                                                                            <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeDelivery(delivery.id || '')} title="Excluir"/>

                                                                            <AccordionButton />
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <Accordion.Body as={`div`} className='delivery-form'>
                                                                {
                                                                    delivery.itens?.map((item, key) => {
                                                                        return (
                                                                            <div key={key}>
                                                                                {
                                                                                    size?.width && size?.width < 992 ? (
                                                                                        item?.products?.map((prod, keyP) => (
                                                                                            <div className='wrapper-delivery-form-body' key={keyP}>
                                                                                                <div className='mobile-body-top'>
                                                                                                    <div className='body-key'>
                                                                                                        {
                                                                                                            keyP == 0 ? (
                                                                                                                <>
                                                                                                                    {key + 1}
                                                                                                                    <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                                    {
                                                                                                                        (item?.products?.length ?? 1) > 1 && (
                                                                                                                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                                        )
                                                                                                                    }
                                                                                                                </>
                                                                                                            ) :
                                                                                                            (
                                                                                                                <div className='v-hidden'>
                                                                                                                    {key + 1}
                                                                                                                    <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='body-remove' onClick={() => removeDeliveryItem(delivery.id, item.id, prod.id)}>
                                                                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                        {t('newOrderScreen.labelDelete')}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='mobile-body-infos'>
                                                                                                    <div className='body-header'>
                                                                                                        <label>{t('newOrderScreen.labelDeliveryText')}</label>
                                                                                                        {item.date ? moment(item.date)?.format('DD/MM/yyyy') ?? '-' : '-'}
                                                                                                    </div>
                                                                                                    <div className='body-header'>
                                                                                                        <label>{t('newOrderScreen.labelProduct')}</label>
                                                                                                        {prod?.materialDescription ?? '-'}
                                                                                                    </div>
                                                                                                    <div className={showErrorForm && (prod?.quantity === 0 ?? undefined) ? 'input-error' : ''}>
                                                                                                        <UniInput
                                                                                                            label={t('newOrderScreen.labelQuantityWeight')}
                                                                                                            type={'number'}
                                                                                                            id='qtd-item'
                                                                                                            htmlLabel={
                                                                                                                <FontAwesomeIcon
                                                                                                                    icon={faInfoCircle}
                                                                                                                    style={{ marginLeft: 5, color: item?.quantity === 0 ? '#EA2027' : '#ed9306', height: 14, cursor: 'help' }}
                                                                                                                    title={(item?.products?.length ?? 1) > 1 ?
                                                                                                                            `${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}` :
                                                                                                                            `${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t ${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}`
                                                                                                                        }
                                                                                                                />
                                                                                                            }
                                                                                                            defaultValue={delivery?.veichleType?.minValue ?? 0}
                                                                                                            placeholder={t('newOrderScreen.labelQtyInTruck')}
                                                                                                            value={prod?.quantity === 0 ? '' : prod?.quantity}
                                                                                                            onChangeInput={(e) => onChangeQuantityItem(e, delivery.id, item.id, prod.productId)}
                                                                                                            onBlurInput={(e) => onBlurQuantityItem(e, delivery.id, item.id, prod.productId, prod.multiple)}
                                                                                                            step={customerData?.multipleWeights?.filter(e => e.materialCode === prod.materialCode)?.[0]?.multipleWeight ?? 1}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='body-header'>
                                                                                                        <label>{t('newOrderScreen.labelVehicle')}</label>
                                                                                                        {delivery?.veichleType?.descriptions?.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? '-'}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        item?.products?.map((prod, keyP) => (
                                                                                            <div className='wrapper-delivery-form-body' key={keyP}>
                                                                                                <div className='body-key'>
                                                                                                    {
                                                                                                        keyP == 0 ? (
                                                                                                            <>
                                                                                                                {key + 1}
                                                                                                                <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                                {
                                                                                                                    (item?.products?.length ?? 1) > 1 && (
                                                                                                                        <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                                    )
                                                                                                                }
                                                                                                            </>
                                                                                                        ) :
                                                                                                        (
                                                                                                            <div className='v-hidden'>
                                                                                                                {key + 1}
                                                                                                                <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='body-header'>
                                                                                                    <label>{t('newOrderScreen.labelDelivery')}</label>
                                                                                                    {item.date ? moment(item.date)?.format('DD/MM/yyyy') ?? '-' : '-'}
                                                                                                </div>
                                                                                                <div className='body-header'>
                                                                                                    <label>{t('newOrderScreen.labelProduct')}</label>
                                                                                                    {prod?.materialDescription ?? '-'}
                                                                                                </div>
                                                                                                <div className={showErrorForm && (prod?.quantity === 0 ?? undefined) ? 'input-error' : ''}>
                                                                                                    <UniInput
                                                                                                        label={t('newOrderScreen.labelQuantityWeight')}
                                                                                                        type={'number'}
                                                                                                        id='qtd-item'
                                                                                                        htmlLabel={
                                                                                                            <FontAwesomeIcon
                                                                                                                icon={faInfoCircle}
                                                                                                                style={{ marginLeft: 5, color: item?.quantity === 0 ? '#EA2027' : '#ed9306', height: 14, cursor: 'help' }}
                                                                                                                title={(item?.products?.length ?? 1) > 1 ?
                                                                                                                        `${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}` :
                                                                                                                        `${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t ${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}`
                                                                                                                    }
                                                                                                            />
                                                                                                        }
                                                                                                        defaultValue={delivery?.veichleType?.minValue ?? 0}
                                                                                                        placeholder={t('newOrderScreen.labelQtyInTruck')}
                                                                                                        value={prod?.quantity === 0 ? '' : prod?.quantity}
                                                                                                        onChangeInput={(e) => onChangeQuantityItem(e, delivery.id, item.id, prod.productId)}
                                                                                                        onBlurInput={(e) => onBlurQuantityItem(e, delivery.id, item.id, prod.productId, prod.multiple)}
                                                                                                        step={customerData?.multipleWeights?.filter(e => e.materialCode === prod.materialCode)?.[0]?.multipleWeight ?? 1}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='body-header'>
                                                                                                    <label>{t('newOrderScreen.labelVehicle')}</label>
                                                                                                    {delivery?.veichleType?.descriptions?.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? '-'}
                                                                                                </div>
                                                                                                <div className='body-remove' onClick={() => removeDeliveryItem(delivery.id, item.id, prod.id)}>
                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                    {t('newOrderScreen.labelDelete')}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    )
                                                                                }

                                                                                <div className='wrapper-delivery-form-inputs'>
                                                                                    {
                                                                                        selectedIncoterm === 'FOB' && (selectedCustomer.country === 'BR' || !selectedCustomer.country) && (
                                                                                            <div className={showErrorForm && (item.transp?.cod === undefined ?? true) ? 'input-error' : ''}>
                                                                                                <UniInput
                                                                                                    id='transp'
                                                                                                    label={t('newOrderScreen.labelShippingCompany')}
                                                                                                    isSelect
                                                                                                    type='select'
                                                                                                    placeholder={t('newOrderScreen.labelSelect')}
                                                                                                    value={item.transp?.cod}
                                                                                                    defaultValue={transporters ? transporters?.filter(t => t.incoterm === selectedIncoterm?.toUpperCase() || t.incoterm === 'AF')?.[0]?.cod ?? transporters[0]?.cod ?? `` : ''}
                                                                                                    onChange={(e) => onChangeTransporter(e, delivery.id, item.id)}
                                                                                                >
                                                                                                    <option disabled value={''} selected>{t('newOrderScreen.labelSelect')}</option>
                                                                                                    {
                                                                                                        transporters?.filter(t => t.incoterm === selectedIncoterm?.toUpperCase() || t.incoterm === 'AF')?.map((tr, idx) => {
                                                                                                            return <option key={idx} value={tr.cod}>{tr.name}</option>
                                                                                                        })
                                                                                                    }
                                                                                                </UniInput>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div>
                                                                                        <UniInput
                                                                                            id='numPed'
                                                                                            label={t('newOrderScreen.labelOrderNumber')}
                                                                                            type='text'
                                                                                            placeholder={t('newOrderScreen.labelOrderNumber')}
                                                                                            value={item.orderNumber}
                                                                                            onChangeInput={(e) => onChangeOrderNumber(e, delivery.id, item.id)}
                                                                                            htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <UniInput
                                                                                            id='numMat'
                                                                                            label={t('newOrderScreen.labelMaterialNumber')}
                                                                                            type='text'
                                                                                            placeholder={t('newOrderScreen.labelMaterialNumber')}
                                                                                            value={item.materialNumber}
                                                                                            onChangeInput={(e) => onChangeMaterialNumber(e, delivery.id, item.id)}
                                                                                            htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <UniInput
                                                                                            id='itemPed'
                                                                                            label={t('newOrderScreen.labelOrdemItem')}
                                                                                            type='text'
                                                                                            placeholder={t('newOrderScreen.labelOrdemItem')}
                                                                                            value={item.orderItem}
                                                                                            onChangeInput={(e) => onChangeOrderItem(e, delivery.id, item.id)}
                                                                                            htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='wrapper-delivery-form-inputs'>
                                                                                    <div className='w-100'>
                                                                                        <UniInput
                                                                                            id='itemComment'
                                                                                            label={t('newOrderScreen.labelObservation')}
                                                                                            type='text'
                                                                                            placeholder={t('newOrderScreen.labelObservation')}
                                                                                            value={item.comment}
                                                                                            onChangeInput={(e) => onChangeOrderItemComment(e, delivery.id, item.id)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {item?.error && <p className='w-100 text-center' style={{ color: '#EA2027' }}>{item?.error}</p>}
                                                                                <div className='divider-body'></div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='d-flex add-deliveries' onClick={() => {
                                        if (selectedProducts === undefined) return;
                                        if (loadingProduct) return;

                                        let weightItemTotal = 0;
                                        let countSelectedProducts = selectedProducts.length;

                                        const itensProducts: DeliveryItemProduct[] = selectedProducts.map(sp => {
                                            let quantity = 1;
                                            let multiple = 1;

                                            if (countSelectedProducts > 1) {
                                                multiple = multipleWeight?.filter((m: any) => m.materialCode === sp.materialCode)?.[0]?.multipleWeight ?? 1;
                                                quantity =  multiple ?? 1;
                                            } else  {
                                                quantity = veichles?.filter(v => v.incoterm === selectedIncoterm)?.[0]?.minValue ?? 0
                                            }

                                            weightItemTotal += quantity;

                                            return {
                                                id: uuidv4(),
                                                materialCode: sp.materialCode,
                                                materialDescription: sp.description,
                                                quantity: quantity,
                                                multiple: multiple,
                                                activitySector: sp.activitySector,
                                                pvc: sp.pvc ?? false,
                                                productId: sp.productId
                                            }
                                        })

                                        const delivery: Delivery = {
                                            id: v4(),
                                            deposit: '-',
                                            quantity: 1,
                                            weight: veichles?.filter(v => v.incoterm === selectedIncoterm)?.[0]?.minValue ?? 0,
                                            veichleType: veichles?.filter(v => v.incoterm === selectedIncoterm)?.[0] ? veichles?.filter(v => v.incoterm === selectedIncoterm)?.[0] : {
                                                tm: '-',
                                                descriptions: [],
                                                incoterm: '',
                                                maxValue: 0,
                                                minValue: 0,
                                                id: '-'
                                            },
                                            itens: [{
                                                id: uuidv4(),
                                                materialNumber: '',
                                                orderItem: '',
                                                orderNumber: '',
                                                quantity: weightItemTotal ?? 0,
                                                transp: transporters?.filter(v => v.incoterm === selectedIncoterm?.toUpperCase() || v.incoterm === 'AF')?.[0] ? {
                                                    cod: transporters?.filter(v => v.incoterm === selectedIncoterm?.toUpperCase() || v.incoterm === 'AF')?.[0]?.cod ?? '',
                                                    name: transporters?.filter(v => v.incoterm === selectedIncoterm?.toUpperCase() || v.incoterm === 'AF')?.[0]?.name ?? ''
                                                } : undefined,
                                                products: itensProducts
                                            }]
                                        }

                                        onAddDelivery(delivery);
                                    }}>
                                        <FontAwesomeIcon icon={faCalendarDays} />
                                        <p>{t('newOrderScreen.labelAdd')} {selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelLoad') : t('newOrderScreen.labelDelivery')}</p>
                                    </div>

                                    <div className='buttons-send-order'>
                                        <p className={showErrorForm ? 'obs-error' : 'obs'}>
                                            {showErrorForm ? t('newOrderScreen.labelRequiredFieldsValidate') : t('newOrderScreen.labelRequiredFields')}
                                        </p>
                                        <Button
                                            onClick={() => goToResume()}
                                            disabled={
                                                deliveries?.some(d => d.error != '' && d.error != undefined) ||
                                                deliveries?.some(d => d.itens?.some(i => i.error != '' && i.error != undefined)) ||
                                                (deliveries?.length === 0 ?? true) ||
                                                selectedIncoterm === undefined ||
                                                selectedPaymentCondition === undefined ||
                                                selectedReceiver === undefined ||
                                                loadingProduct
                                            }
                                            title={
                                                selectedReceiver === undefined ? t('newOrderScreen.labelSelectedReceiver') :
                                                    selectedIncoterm === undefined ? t('newOrderScreen.labelSelectedIncoterm') :
                                                        selectedPaymentCondition === undefined ? t('newOrderScreen.labelPaymentCondition') :
                                                            (deliveries?.length === 0 ?? true) ? t('newOrderScreen.labelMessageDeliveryDate') : t('newOrderScreen.labelSendOrder')
                                            }
                                        >
                                            {t('newOrderScreen.labelSendOrder')}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <UniNewOrderResume
                                deliveries={deliveries?.sort((a, b) => {
                                    try
                                    {
                                        if (typeof (a.date) === 'object') {
                                            if(typeof (b.date) === 'object')
                                                return (a.date?.getTime() ?? 0) - (b.date?.getTime() ?? 0);
                                            else if(typeof (b.date) === 'string')
                                            {
                                                return (a.date?.getTime() ?? 0) - (new Date(b.date)?.getTime() ?? 0);
                                            }
                                        } else if (typeof (a.date) === 'string' && typeof (b.date) === 'string') {
                                            return (new Date(a.date)?.getTime() ?? 0) - (new Date(b.date)?.getTime() ?? 0);
                                        }
                                        return -1;
                                    }
                                    catch(err:any)
                                    {
                                        return -1;
                                    }

                                }) ?? deliveries}
                                selectedProducts={selectedProducts}
                                incoterm={selectedIncoterm}
                                sendOrders={() => sendOrders()}
                                setObs={(e) => setObs(e.target?.value ?? '')}
                                setShowResume={() => {
                                    setShowResume(false)
                                }}
                                weightTon={weightTon}
                                loading={loadingCreate} />
                        )
                    }
                </div>
                <div className='new-order-instructions'>
                    <div className='img'></div>

                    <div className='instructions'>
                        <div className='flag'></div>
                        <p className='instructions-title'>{t('newOrderScreen.rulesTitle')}</p>
                        <p className='instructions-body'>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule1')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule2')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule3')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule4')}</p>

                        </p>
                    </div>
                </div>
            </div>
            <UniModal
                icon={tuckLocation}
                title={t('newOrderScreen.labelReturnOkOrder')}
                message=''
                show={showModalSucces}
                onHide={() => {
                    setShowModalSucces(false);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={100}
            >
                <div className='modal-body-resume'>
                    <p style={{ margin: 0 }}>{t('newOrderScreen.labelComingSoon')}</p>
                    <p>{t('newOrderScreen.labelAccessArea')} <span>{t('newOrderScreen.labelMyOrders')}</span></p>
                    <Button onClick={() => navigate('/my-orders')}>{t('newOrderScreen.labelForMyOrders')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faCalendarXmark}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderTextValidDate')}
                message=''
                iconColor='#EA2027'
                show={msgErrorDate !== ''}
                onHide={() => {
                    setErrorDate('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorDate}</p>
                    <Button onClick={() => setErrorDate('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>
            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('newOrderScreen.labelErrorMessageOrder')}
                message=''
                iconColor='#EA2027'
                show={msgErrorIntent !== undefined}
                onHide={() => {
                    setErrorIntent(undefined);
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <div>
                        {
                            msgErrorIntent?.map((d, key) => {
                                return (<p> - {d.error}</p>)
                            })
                        }
                    </div>
                    <Button onClick={() => setErrorIntent(undefined)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderFileImport')}
                message=''
                iconColor='#EA2027'
                show={msgErrorFileModel !== ''}
                onHide={() => {
                    setErrorFileModel('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorFileModel}</p>
                    <Button onClick={() => setErrorFileModel('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('newOrderScreen.labelImportFile')}
                message=''
                iconColor='#EA2027'
                show={msgErrorOrderIntentImport != undefined}
                onHide={() => {
                    setErrorOrderIntentImport(undefined);
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{t('newOrderScreen.labelErrorImportFile')}</p>
                    {msgErrorOrderIntentImport?.map(d => <p>{d}</p>)}
                    <Button onClick={() => setErrorOrderIntentImport(undefined)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faCheckCircle}
                isFontAwsome
                title={t('newOrderScreen.labelImportFile')}
                message=''
                iconColor='#00FF3C'
                show={msgOrderIntentImport != ''}
                onHide={() => {
                    setOrderIntentImport('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgOrderIntentImport}</p>
                    <Button onClick={() => setOrderIntentImport('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faWarning}
                isFontAwsome
                title={t('newOrderScreen.labelMultipleQuantityError')}
                message=''
                iconColor='#ECEF4E'
                show={showNotMultiple}
                onHide={() => {
                    setShowNotMultiple(false);
                }}
                iconWidth={50}
                iconHeight={60}
            >
                <div className='modal-body-resume'>
                    <p>{t('newOrderScreen.labelMultipleQuantityError').replace('[informedQuantity]',informedQuantity).replace('[multipleWeight]',multipleWeight.toString())}</p>
                    <Button onClick={() => setShowNotMultiple(false)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faFileUpload}
                isFontAwsome
                title={t('newOrderScreen.labelImportFileOrderIntent')}
                message=''
                iconColor='#00FF3C'
                show={showMassiveImport}
                onHide={() => {
                    setErrorFile('');
                    setShowMassiveImport(false);
                }}
                iconWidth={75}
                iconHeight={75}
            >
                <div className='modal-body-resume-upload'>
                    {(selectedCustomer?.country === 'BR' || selectedCustomer?.country == null) && <p style={{maxWidth: 480}}>
                        {t('newOrderScreen.labelTextModelPart1')} <span className='link-upload' onClick={() =>{(incoterms?.indexOf('SFR') ?? 0) >=0 ? downloadFileModel('SFR') : downloadFileModel('CEF')}}>{ (incoterms?.indexOf('SFR') ?? 0) >=0 ? t('newOrderScreen.labelSFRFile') : t('newOrderScreen.labelCIFFile') }</span> {(incoterms?.indexOf('SFR') ?? 0) === -1 ? t('newOrderScreen.labelTextModelPart2') : ''} <span className='link-upload' onClick={() =>{downloadFileModel('FOB')}}>{(incoterms?.indexOf('SFR') ?? 0) === -1 ? t('newOrderScreen.labelFOBFile'): ''}</span> {t('newOrderScreen.labelTextModelPart3')}.
                    </p>}
                    {selectedCustomer?.country === 'AR' && <p style={{maxWidth: 420}}>
                        {t('newOrderScreen.labelTextModelARPart1')} <span className='link-upload' onClick={() =>{downloadFileModel('FCA')}}>{t('newOrderScreen.labelTextModelARPart2')}</span> {t('newOrderScreen.labelTextModelARPart3')}
                    </p>}
                    {
                        fileError && (<p style={{ color: '#EA2027', fontSize: 12, fontWeight: 600 }}>{fileError}</p>)
                    }
                    {
                        !file?.name && !loadingFile.loading ? (
                            <label className='button-upload'>
                                <input
                                    id='file-upload'
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                    onChange={handleFileSelected}
                                />
                                <FontAwesomeIcon icon={faFileUpload} />
                                <p>{t('newOrderScreen.labelChooseFile')}</p>
                            </label>
                        ) : loadingFile.loading ? (
                            <div className='file-wrapper'>
                                <ProgressBar animated now={loadingFile.percent ?? 0} max={100} min={0} />
                                <FontAwesomeIcon icon={faXmark} onClick={() => {
                                    setFile(undefined);
                                    setLoadingFile({
                                        loading: false,
                                        percent: 0
                                    })
                                }} />
                            </div>
                        ) : (
                            <div className='file-wrapper'>
                                <div className='file-name'>
                                    <span>{t('newOrderScreen.labelFileSelected')}</span>
                                    <p>{file?.name}</p>
                                </div>
                                <FontAwesomeIcon icon={faXmark} onClick={() => setFile(undefined)} />
                            </div>
                        )
                    }

                    <div className='d-flex upload-buttons'>
                        <Button variant='outline' onClick={() => { setErrorFile(''); setShowMassiveImport(false); }}>{t('newOrderScreen.labelCancel')}</Button>
                        <Button variant='primary' onClick={() => {
                            if (!loadingMassiveImport) {
                                handleUploadFileOrderIntent();
                            }
                        }}
                            disabled={loadingMassiveImport || !file}
                        >
                            {loadingMassiveImport ? <Spinner color='#fff'></Spinner> : t('newOrderScreen.labelImport')}
                        </Button>
                    </div>
                </div>
            </UniModal>

            <UniModal
                icon={faInfoCircle}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderTextValidQuantity')}
                message=''
                iconColor='#EA2027'
                show={msgErrorQuantity !== ''}
                onHide={() => {
                    setMsgErrorQuantity('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorQuantity}</p>
                </div>
            </UniModal>

            <UniModal
                icon={faWarning}
                isFontAwsome
                title= {t('newOrderScreen.titleErrorOnSap')}
                message=''
                iconColor='#ECEF4E'
                show={msgErrorsOnSap}
                onHide={() => {
                    setMsgErrorsOnSap(false);
                }}
                iconWidth={50}
                iconHeight={60}
            >
                <div className='modal-body-resume'>
                    <p>{t('newOrderScreen.errorOnSapLineOne')}</p>
                    <p>{t('newOrderScreen.errorOnSapLineTwo')}</p>
                    <>
                            <Button style={{ backgroundColor: '#FF0000' }} variant='primary' onClick={() => navigate('/my-orders')}>{t('myOrders.no')}</Button>&nbsp;
                            <Button variant='primary' onClick={() => setMsgErrorsOnSap(false)}>{t('myOrders.yes')}</Button>
                    </>
                </div>
            </UniModal>

        </>
    )
}

export default NewOrder;