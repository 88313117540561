import { isArray } from "util";

export default async function callApi(method: string, URI: string, body: any = null) {
    const tkn = localStorage.getItem('tkn');
    const url = `${process.env.REACT_APP_API}/${URI}`;

    let myHeaders =  new Headers(
        {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
    );

    let configCall: RequestInit = { 
        method,
        headers: myHeaders,
    };
    
    if (method !== 'GET') {
        configCall.body = JSON.stringify(body)
    }

    try {
        const r = await fetch(url, configCall);
        if (!r.ok) {      
            if (r.status === 401) {
                localStorage.clear();
                window.location.pathname = '/';
            }

            if (r.status === 403) {
                localStorage.clear();
                window.location.pathname = '/';
            }            

            const errResponse = await r.json();
            throw errResponse;
        }
        else {
            if (r.status === 204) return;
            
            return await r.json();
        }
    } catch (err_1) {
        throw err_1;
    }
}

export async function callApiFinancial(method: string, URI: string, body: any = null) {
    const tkn = localStorage.getItem('tkn');
    const url = `${process.env.REACT_APP_API_FINANCIAL}/${URI}`;

    let myHeaders =  new Headers(
        {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
    );

    let configCall: RequestInit = { 
        method,
        headers: myHeaders,
    };
    
    if (method !== 'GET') {
        configCall.body = JSON.stringify(body)
    }

    try {
        const r = await fetch(url, configCall);
        if (!r.ok) {      
            if (r.status === 401) {
                localStorage.clear();
                window.location.pathname = '/';
            }

            const errResponse = await r.json();
            throw errResponse;
        }
        else {
            if (r.status === 204) return;
            
            return await r.json();
        }
    } catch (err_1) {
        throw err_1;
    }
}

export async function callApiBlob(method: string, URI: string, body: any = null) {
    const tkn = localStorage.getItem('tkn');
    const url = `${process.env.REACT_APP_API}/${URI}`;

    let myHeaders =  new Headers(
        {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
    );

    let configCall: any = { 
        method,
        headers: myHeaders,
        responseType: 'blob'
    };
    
    if (method !== 'GET') {
        configCall.body = JSON.stringify(body)
    }

    try {
        const r = await fetch(url, configCall);
        if (!r.ok) {      
            if (r.status === 401) {
                localStorage.clear();
                window.location.pathname = '/';
            }

            const errResponse = await r.json();
            throw errResponse;
        }
        else {
            if (r.status === 204) return;
            
            return await r.blob();
        }
    } catch (err_1) {
        throw err_1;
    }
}

export async function callApiBlobFinancial(method: string, URI: string, body: any = null) {
    const tkn = localStorage.getItem('tkn');
    const url = `${process.env.REACT_APP_API_FINANCIAL}/${URI}`;

    let myHeaders =  new Headers(
        {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
    );

    let configCall: any = { 
        method,
        headers: myHeaders,
        responseType: 'blob'
    };
    
    if (method !== 'GET') {
        configCall.body = JSON.stringify(body)
    }

    try {
        const r = await fetch(url, configCall);
        if (!r.ok) {      
            if (r.status === 401) {
                localStorage.clear();
                window.location.pathname = '/';
            }

            const errResponse = await r.json();
            throw errResponse;
        }
        else {
            if (r.status === 204) return;
            
            return await r.blob();
        }
    } catch (err_1) {
        throw err_1;
    }
}

export async function callApiFile(method: string, URI: string, file: File | File[], json: any = undefined) {
    const tkn = localStorage.getItem('tkn');
    const url = `${process.env.REACT_APP_API}/${URI}`;

    let myHeaders =  new Headers(
        {
            'Authorization': `Bearer ${tkn}`,
        }
    );

    let configCall: RequestInit = { 
        method,
        headers: myHeaders,
    };
    
    var data = new FormData();
    if(Array.isArray(file)) {
        file.forEach(file => {
            data.append('files', file);
        })
    } else {
        data.append('file', file);
    }

    if(json)
        data.append('json', JSON.stringify(json));

    configCall.body = data

    try {
        const r = await fetch(url, configCall);
        if (!r.ok) {      
            if (r.status === 401) {
                localStorage.clear();
                window.location.pathname = '/';
            }

            const errResponse = await r.json();
            throw errResponse;
        }
        else {
            if (r.status === 204) return;
            
            return await r.json();
        }
    } catch (err_1) {
        throw err_1;
    }
}

export interface BaseResponse {
    error?: string,
    errorPT?: string,
    errorES?: string,
    errorEN?: string,    
    success: boolean
}

export interface BaseResponseDto<T> {
    data: T,
    error?: string,
    errorPT?: string,
    errorES?: string,
    errorEN?: string,
    success: boolean,
    errors?: string[]
}

export interface BaseResponsePagedDto<T> {
    data: T,
    error?: string,
    errorPT?: string,
    errorES?: string,
    errorEN?: string,
    success: boolean,
    totalPages: number,
    totalItems: number,
    currentTake: number
}

export interface UniToken {
    idp: string,
    sub: string,
    name: string,
    tfp: string,
    azp: string,
    ver: string,
    iat: Number,
    aud: string,
    exp: Number,
    iss: string,
    nbf: Number,
    extension_Profile: string,
}