import moment from "moment";
import { Delivery } from "../types/newOrder";
import { StatusIntent } from "../types/orderIntent";
import callApi, { BaseResponseDto, BaseResponsePagedDto, callApiBlob, callApiBlobFinancial, callApiFile, callApiFinancial } from "./baseRequest";
import { OrderIntentResponseDto } from "./orderHistoryService";

const routeIntent = 'financial';

export interface Extract {
    invoice: string,
    serieNumber: string,    
    instalment: string,
    salesNumber: string,
    paymentConditionCode: string,
    filialCode: string,
    status: string,
    currency: string,
    emissionDate: string,
    invoicedDate: string,
    expiredDate: string,
    aUGDT: string,
    value: string,
    fees: string,
    totalValue: string,
    center: string,
    bRANCH: string,
    customerCode: string,
    customerName: string,
    paymentCondition: string,
    bANKL: string,
    financialDocument: string,
    document: string,
    documentYear: string,
    paymentForm: string,
    pDAYS: string,
    zSOLL: string,
    zJDIAS: string,
    vBELV: string,
    paymentFormDescription: string,
    contractNumber: string,
    contractNumberSAP: string,
    statusFlag: string,
    checked: boolean
}

export interface ItauCripto {
    dc: string
}

export function getFinancialData(
    take: number, 
    skip: number, 
    issuer: string,
    status: string,
    search?: string, 
    initialDate?: string,    
    finalDate?: string,
    periodType?: string): Promise<BaseResponsePagedDto<Extract[]>> {
    const result: BaseResponsePagedDto<Extract[]> = {
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',        
        data: [],
        success: false,
        currentTake: take,
        totalItems: 0,
        totalPages: 0
    };

    const response = callApiFinancial(
        'GET', 
        `${routeIntent}/detail/${issuer}/${status}?take=${take}&skip=${skip}&initialDate=${initialDate}&finalDate=${finalDate}&nfNum=${search}&periodType=${periodType}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) {
            result.success = true;
            result.data = resp?.data;
            result.totalItems = resp?.totalItems;
            result.totalPages = resp?.totalPages;
        }
        else {
            result.error = resp?.error;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
            result.errorPT = resp?.errorPT ;           
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;

        return result;
    })

    return response;
};

export function getCriptoCode(
    cnpjCpf: string | undefined,
    salesOrg: string | undefined): Promise<BaseResponseDto<ItauCripto>> {
    const result: BaseResponseDto<ItauCripto> = {
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',         
        data: {
            dc: ''
        },
        success: false,
    };

    const response = callApi(
        'GET', 
        `${routeIntent}/get-cripto-code?cnpjCpf=${cnpjCpf}&salesOrg=${salesOrg}`
    )
    .then(resp => {
        if (resp?.error == null ?? false) 
        {
            result.success = true;
            result.data = resp?.data;
        }
        else {
            result.error = resp?.error;
            result.errorEN = resp?.errorEN;
            result.errorES = resp?.errorES;
            result.errorPT = resp?.errorPT;  
        }
        return result;
    })
    .catch(err => {
        result.error = err.error;

        return result;
    })

    return response;
}

export function getCertify(invoiceNumbers: string[]):Promise<boolean> {
    let queryParams = ``;

    if (!invoiceNumbers) return Promise.resolve(false);

    invoiceNumbers.forEach(invoiceNumber => {
        if(queryParams) {
            queryParams = queryParams + `&invoices=${invoiceNumber}`;
        } else {
            queryParams = `?invoices=${invoiceNumber}`;
        }
    });

    let url = `${routeIntent}/digital-certificate/${queryParams}`;

    const p = callApiBlobFinancial(
        'GET', 
        url
    )
    .then((resp) => {   
        const blob = new Blob([resp as BlobPart], {
            type: 'application/octet-stream'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = invoiceNumbers.length > 1 ? `Certify${moment().format('DD-MM-yyyy')}.zip` : `Certify${moment().format('DD-MM-yyyy')}.pdf`;
        document.body.appendChild(element);
        element.click();  
        
        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
}

export function getRemito(shipmentNumbers: string[]): Promise<boolean> {
    let queryParams = ``;

    shipmentNumbers.forEach(shipmentNumber => {
        if(queryParams) {
            queryParams = queryParams + `&shipmentNumber=${shipmentNumber}`;
        } else {
            queryParams = `?shipmentNumber=${shipmentNumber}`;
        }
    })

    let url = `${routeIntent}/remito/${queryParams}`;

    const p = callApiBlobFinancial(
        'GET', 
        url
    )
    .then((resp) => {   
        const blob = new Blob([resp as BlobPart], {
            type: 'application/octet-stream'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = shipmentNumbers.length > 1 
            ? `Remito-${shipmentNumbers}${moment().format('DD-MM-yyyy')}.zip`
            : `Remito-${shipmentNumbers}${moment().format('DD-MM-yyyy')}.pdf`;
        document.body.appendChild(element);
        element.click();  
        
        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
};

export function getShipments(invoiceNumber: string): Promise<BaseResponseDto<string[] | null>> {
    let url = `${routeIntent}/shipments/${invoiceNumber}`;

    const result: BaseResponseDto<string[] | null> = {
        error: '',
        data: null,
        success: false,
    };

    const p = callApi(
        'GET', 
        url
    )
    .then((resp) => {   
        if (resp?.error == null) {
            result.data = resp.data;
            result.success = true;
            return result;
        } else {
            result.error = resp?.error;
        }

        return result;
    })
    .catch(err => {
        result.error = err;
        return result;
    })

    return p;
};

export function getCertifyAR(shipmentNumber: string): Promise<boolean> {
    let url = `${routeIntent}/digital-certificate-ar/${shipmentNumber}`;

    const p = callApiBlobFinancial(
        'GET', 
        url
    )
    .then((resp) => {   
        const blob = new Blob([resp as BlobPart], {
            type: 'application/octet-stream'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = `CertifyAR${moment().format('DD-MM-yyyy')}.pdf`;
        document.body.appendChild(element);
        element.click();  
        
        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
};

export function getXML(invoiceNumbers: string[]): Promise<boolean> {
    let queryParams = ``;

    invoiceNumbers.forEach(invoiceNumber => {
        if(queryParams) {
            queryParams = queryParams + `&invoices=${invoiceNumber}`;
        } else {
            queryParams = `?invoices=${invoiceNumber}`;
        }
    });

    let url = `${routeIntent}/xml/${queryParams}`;

    const p = callApiBlobFinancial(
        'GET', 
        url
    )
    .then((resp) => {   
        const blob = new Blob([resp as BlobPart], {
            type: 'text/xml'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = invoiceNumbers.length > 1 ? `XML${moment().format('DD-MM-yyyy')}.zip` : `XML${moment().format('DD-MM-yyyy')}.xml`;
        document.body.appendChild(element);
        element.click();  
        
        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
};

export function getDanfe(invoiceNumbers: string[]): Promise<boolean> {
    let queryParams = ``;

    invoiceNumbers.forEach(invoiceNumber => {
        if(queryParams) {
            queryParams = queryParams + `&shipmentNumber=${invoiceNumber}`;
        } else {
            queryParams = `?shipmentNumber=${invoiceNumber}`;
        }
    });

    let url = `${routeIntent}/danfe/${queryParams}`;

    const p = callApiBlobFinancial(
        'GET', 
        url
    )
    .then((resp) => {   
        const blob = new Blob([resp as BlobPart], {
            type: 'application/octet-stream'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = invoiceNumbers.length > 1 ? `Danfe${moment().format('DD-MM-yyyy')}.zip` : `Danfe${moment().format('DD-MM-yyyy')}.pdf`;
        document.body.appendChild(element);
        element.click();
        
        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
};

export function getFileFinancial(
    issuer: string,
    status: string,
    search?: string, 
    initialDate?: string,    
    finalDate?: string,
    nfs?: string[]) : Promise<boolean>
{    
    let url = `${routeIntent}/excel/detail/${issuer}/${status}?initialDate=${initialDate}&finalDate=${finalDate}&nfNum=${search}`;
    nfs?.forEach(nf => {
        url = url + `&nfs=${nf}`;
    })

    const p = callApiBlobFinancial(
        'GET', 
        url
    )
    .then((resp) => {   
        const blob = new Blob([resp as BlobPart], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = `Financial${moment().format('DD-MM-yyyy')}.xlsx`;
        document.body.appendChild(element);
        element.click();  
        
        return true;
    })
    .catch(err => {
        return false;
    })

    return Promise.resolve(p);
};
