import {create} from 'zustand';
import { persist, devtools, createJSONStorage, StorageValue} from 'zustand/middleware';
import { Profile } from '../../services/userService';
import { decryptByte, encryptByte } from '../../helpers/encrypt';

type UserActionProps = {
}

export type Customer = {
    customerId: string,
    issuer: string,
    name: string,
    cpfCnpj: string,
    country: string,
    searchTerm: string,
    enabledOrderAfterHour: boolean
}

type LoginData = {
    customers: Customer[],
    profile?: Profile,
    id: string,
    name: string,
    email: string,
    showCustomerSurvey?: boolean,
    showCustomerSurveyLoose?: boolean,
    hasNotices?: boolean,
}

type UserStoreProps = {
    loginData: LoginData,
    darkMode: boolean,
    lng: string,
    selectedCustomer: Customer,
    changeDarkMode: (darkMode: boolean) => void;
    changeLng: (lng: string) => void;
    changeLoginData: (data: LoginData) =>  void,
    changeSelectedCustomer: (customer: Customer) =>  void,
}

export const useUserStore = create<UserStoreProps, [["zustand/persist", UserStoreProps]]>
(
    persist(
        (set) => ({
            loginData: {
                id: '',
                customers: [],
                name: '',
                profile: Profile.Adm,
                email: '',
                showCustomerSurvey: false,
                hasNotices: false
            },
            darkMode: false,
            lng: 'pt',
            selectedCustomer: {
                cpfCnpj: '',
                customerId: '',
                issuer: '',
                name: '',
                country: '',
                searchTerm: '',
                enabledOrderAfterHour: false
            },
            changeDarkMode: (darkMode: boolean) => set(() => ({
                darkMode: darkMode
            })),
            changeLng: (lng: string) => set(() => ({
                lng: lng
            })),
            changeLoginData: (data: LoginData) => set(() => ({
                loginData: data
            })),
            changeSelectedCustomer: (customer: Customer) => set(() => ({
                selectedCustomer: customer
            })),
        }),
        {
            name: 'stro',
            storage: {
                getItem: (name) => {
                    let str = localStorage.getItem(name);
                    if (!str) return null;

                    str = decryptByte(str);
                    if (!str) return null;
                    const { state } = JSON.parse(str);
                    return {
                      state: {
                        ...state,
                      },
                    }
                },
                setItem: (name, newValue: StorageValue<UserStoreProps>) => {
                    const str = JSON.stringify({
                      state: {
                        ...newValue.state,
                      },
                    })
                    localStorage.setItem(name, encryptByte(str))
                  },
                removeItem: (name) => localStorage.removeItem(name),
            }
        }
    )
);