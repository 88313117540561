import "./i18n";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedApp from './ProtectedApp';
import 'react-loading-skeleton/dist/skeleton.css';
import reportWebVitals from "./reportWebVitals";
import ForgotPasssword from "./pages/ForgotPassword";
import Register from "./pages/Register";
import NewOrder from "./pages/NewOrder";
import MyOrders from "./pages/MyOrders";
import ApproveChanges from "./pages/ApproveChanges";
import EditOrder from "./pages/EditOrder";
import RebuyOrder from "./pages/RebuyOrder";
import UtilLinks from "./pages/UtilLinks";
import Financial from "./pages/Financial";
import AccessAs from "./pages/AccessAs";
import CallCenter from "./pages/CallCenter";
import CallCenterNew from "./pages/CallCenterNew";
import CallCenterView from "./pages/CallCenterView";
import "react-datepicker/dist/react-datepicker.css";
import MailSetting from "./pages/MailSetting";
import BulkOrderEdit from "./pages/BulkOrderEdit";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
      path: "/",
      element: <Login />
  },
  {
    path: "/forgot-password",
    element: <ForgotPasssword />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
      path: "/home",
      element: (
        <ProtectedApp activeRoute='home'>
          <Home />
        </ProtectedApp>
      ),
  },
  {
      path: "/new-order",
      element: (
        <ProtectedApp activeRoute='new-order'>
          <NewOrder />
        </ProtectedApp>
      ),
  },
  {
    path: "/edit-order/:id",
    element: (
      <ProtectedApp activeRoute='my-orders'>
        <EditOrder />
      </ProtectedApp>
    ),
  },
  {
    path: "/rebuy/:id",
    element: (
      <ProtectedApp activeRoute='my-orders'>
        <RebuyOrder />
      </ProtectedApp>
    ),
  },
  {
      path: "/my-orders",
       element: (
        <ProtectedApp activeRoute='my-orders'>
          <MyOrders />
        </ProtectedApp>
      ),
  },
  {
      path: "/financial",
       element: (
        <ProtectedApp activeRoute='financial'>
          <Financial />
        </ProtectedApp>
      ),
  },
  {
      path: "/call-center",
       element: (
        <ProtectedApp activeRoute='call-center'>
          <CallCenter />
        </ProtectedApp>
      ),
  },
  {
      path: "/call-center/new",
       element: (
        <ProtectedApp activeRoute='call-center'>
          <CallCenterNew />
        </ProtectedApp>
      ),
  },
  {
      path: "/call-center/view",
       element: (
        <ProtectedApp activeRoute='call-center'>
          <CallCenterView />
        </ProtectedApp>
      ),
  },
  {
    path: "/links",
     element: (
      <ProtectedApp activeRoute='links'>
        <UtilLinks />
      </ProtectedApp>
    ),
  },
  {
    path: "/approve/:idOrder",
     element: (
      <ProtectedApp activeRoute='approve'>
        <ApproveChanges />
      </ProtectedApp>
    ),
  },
  {
    path: "/access-as",
     element: (
      <AccessAs />
    ),
  },
  {
    path: "/mail-setting",
     element: (
      <ProtectedApp activeRoute=''>
        <MailSetting />
      </ProtectedApp>

    ),
  },
  {
    path: "/bulk-edit/:ids",
     element: (
      <ProtectedApp activeRoute='my-orders'>
        <BulkOrderEdit />
      </ProtectedApp>
    ),
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();

