import '../styles/MailSetting.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import { useEffect, useState } from 'react';
import { MailConfiguration, getEmptyMailConfiguration, getMailUserConfiguration, userConfigurationCreateOrUpdate } from '../services/mailSettingsService';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UniModal from '../components/UniModal/UniModal';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../store/user';

export type MailOptions = {
    value: string,
    description: string
}

export const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

    const handleWindowSizeChange = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    return screenWidth
}

const MailSetting = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [mailConfiguration, setMailConfiguration] = useState<MailConfiguration>();
    const { t } = useTranslation();
    const [disableAll, setDisableAll] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalError, setShowModalError] = useState<boolean>(false);
    const navigate = useNavigate();
    const { selectedCustomer, loginData } = useUserStore();

    useEffect(() => {
        setMailConfiguration(getEmptyMailConfiguration());
        getMailUserConfiguration(loginData.id, selectedCustomer.issuer)
            .then(resp => {
                if (resp?.success ?? false) {
                    setMailConfiguration({ ...resp.data });

                } else {
                    setMailConfiguration(getEmptyMailConfiguration());
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        checkAllDisabled();
    }, [mailConfiguration])

    const createOrUpdate = () => {
        if (mailConfiguration){
            userConfigurationCreateOrUpdate(mailConfiguration).then(resp => {
                if (resp?.success ?? false) {
                    setShowModal(true);
                } else {
                    setShowModalError(true);
                }
            })
                .catch(e => console.log(e))
                .finally(() => setLoading(false))
        }
    }

    const onChangeValues = (e: any, prop: string) => {
        let modelMailConfiguration = mailConfiguration;
        if (!modelMailConfiguration || !validatePropName(prop)) return;

        switch (prop) {
            case 'departureEmail':
                modelMailConfiguration.departureEmail = e;
                break;
            case 'scheduledEmail':
                modelMailConfiguration.scheduledEmail = e;
                break
            case 'invoicedEmail':
                modelMailConfiguration.invoicedEmail = e;
                break
        }

        setMailConfiguration({ ...modelMailConfiguration });
        checkAllDisabled();
    }

    const setSwitchValue = (prop: string) => {
        switch (prop) {
            case 'departureEmail':
                return mailConfiguration?.departureEmail;
            case 'scheduledEmail':
                return mailConfiguration?.scheduledEmail;
            case 'invoicedEmail':
                return mailConfiguration?.invoicedEmail;
        }
    }

    const onChangeValuesAll = (e: any, prop: string) => {
        let modelMailConfiguration = mailConfiguration;
        if (!modelMailConfiguration) return;

        setDisableAll(e);
        modelMailConfiguration.scheduledEmail = e;
        modelMailConfiguration.invoicedEmail = e;
        modelMailConfiguration.departureEmail = e;

        setMailConfiguration({ ...modelMailConfiguration });
    }

    const validatePropName = (name: string) => {
        switch (name) {
            case 'scheduledEmail':
            case 'pending':
            case 'departureEmail':
            case 'entry':
            case 'invoicedEmail':
                return true;
            default:
                return false;
        }
    }

    const goHome = () => {
        navigate('/home');
    }

    const checkAllDisabled = () => {
        if (mailConfiguration?.scheduledEmail == true && mailConfiguration?.invoicedEmail == true && mailConfiguration?.departureEmail == true)
            setDisableAll(true);
        else
            setDisableAll(false);
    }

    const SaveSettings = () => {
        let modelMailConfiguration = mailConfiguration;
        if(modelMailConfiguration){
            modelMailConfiguration.userId = loginData.id;
            modelMailConfiguration.customerCode = selectedCustomer.issuer;
            setMailConfiguration({...modelMailConfiguration});

            createOrUpdate()
        }

    }

    const mailOptions: MailOptions[] = [
        {
            value: 'scheduledEmail',
            description: t('mailSettings.schedule')
        },
        // {
        //     value: 'pending',
        //     description: 'Mudança de status para pendente'
        // },
        {
            value: 'departureEmail',
            description: t('mailSettings.departure')
        },
        // {
        //     value: 'entry',
        //     description: 'Mudança de status para entrada na fábrica'
        // },
        {
            value: 'invoicedEmail',
            description: t('mailSettings.invoiced')
        }
    ]
    let leftColumnOptions: MailOptions[] = [];
    let rightColumnOptions: MailOptions[] = [];

    if (useScreenWidth() <= 1356) {
        leftColumnOptions = mailOptions;
        rightColumnOptions = [];
    }
    else {
        const middleIndex = Math.ceil(mailOptions.length / 2);
        leftColumnOptions = mailOptions.slice(0, middleIndex);
        rightColumnOptions = mailOptions.slice(middleIndex);
    }

    return (
        <>
            <div className="mail-setting-wrapper">
                <div className='mail-setting-header w-100'>
                    <div className='mail-setting-header-i'>
                        <FontAwesomeIcon icon={faMailBulk} color='#00FF3C' size='xl' />
                        <p>{t('mailSettings.mailSettings')} </p>
                    </div>
                </div>
                <div className='mail-setting-body'>
                    <div className='body-area'>
                        <div className='body-head'>

                            <p className='body-title'> {t('mailSettings.whatNotification')}</p>
                            <div className='head-all'><span>{t('mailSettings.disableNotification')}</span>
                                <Switch
                                    onChange={(e: any) => { onChangeValuesAll(e, 'enabled') }}
                                    disabled={loading}
                                    checked={disableAll}
                                    height={20}
                                    width={40}
                                />
                            </div>
                        </div>
                        <div className='divider-line'></div>
                        <div className='table-options'>
                            <div className="column">
                                {leftColumnOptions?.map(option => (
                                    <div className='option-area' key={option.value}>
                                        <div>{option.description}</div>
                                        <div>
                                            <Switch
                                                onChange={(e: any) => { onChangeValues(e, option.value) }}
                                                disabled={loading}
                                                checked={setSwitchValue(option.value) ?? false}
                                                height={20}
                                                width={40}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {rightColumnOptions && rightColumnOptions.length > 0 && (
                                <div className="column divider-column">
                                    {rightColumnOptions?.map(option => (
                                        <div className='option-area' key={option.value}>
                                            <div>{option.description}</div>
                                            <div>
                                                <Switch
                                                    onChange={(e: any) => { onChangeValues(e, option.value) }}
                                                    disabled={loading}
                                                    checked={setSwitchValue(option.value) ?? false}
                                                    height={20}
                                                    width={40}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='buttons-save'>
                        <Button
                            onClick={() => SaveSettings()}
                            disabled={false}
                            title='Salvar'>
                            {t('mailSettings.save')}
                        </Button>
                    </div>
                </div>

            </div>

            <UniModal
                icon={faCheckCircle}
                isFontAwsome
                title={t('mailSettings.sucess')}
                message={t('mailSettings.sucess')}
                iconColor='#00FF3C'
                show={showModal}
                onHide={() => {
                    goHome();
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <Button onClick={() => goHome()}>Ok</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faCheckCircle}
                isFontAwsome
                title={t('mailSettings.error')}
                message={t('mailSettings.error')}
                iconColor='#EA2027'
                show={showModalError}
                onHide={() => {
                    goHome();
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <Button onClick={() => goHome()}>Ok</Button>
                </div>
            </UniModal>

        </>
    )
}

export default MailSetting;