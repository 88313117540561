import '../styles/CallCenterView.scss';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import callCenter from '../assets/icons/call-center.svg';
import ticketHistoryUserIcon from '../assets/icons/ticket-history-user-icon.svg';
import ticketHistoryUniparIcon from '../assets/icons/ticket-history-unipar-icon.svg';
import circleCheckMarkGreen from '../assets/icons/circle-checkmark-green.svg';
import warningBlack from '../assets/icons/warning-black.svg';
import { addTicketFile, AlterTicket, alterTicket, createTicketReply, getTicket, removeTicketFile, ResponseTicket, ResponseTicketHistory, TicketStatusEnum } from '../services/callCenterService';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import UniModal from '../components/UniModal/UniModal';
import { useTranslation } from "react-i18next";
import UniInputFile, { UniInputFileReference } from '../components/UniInputFile/UniInputFile';
import { callCenterStatusStyle, getTicketDescriptionStatus } from '../helpers/enums';
import { getMimeTypeFromExtension } from '../helpers/utils';
import Skeleton from 'react-loading-skeleton';
import { useUserStore } from '../store/user';
import { Form, Spinner } from 'react-bootstrap';
import { FormControlElement } from '../components/UniInput/UniInput';

const CallCenterView = () => {    
    const { darkMode } = useUserStore();
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<ResponseTicket>({} as ResponseTicket);
    // Using a variable so this can be parametrized in the future
    const [daysToFinishTicket] = useState(2);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModal, setErrorModal] = useState('');
    const [errorModalTitle, setErrorModalTitle] = useState('');
    const [showConfirmFinishedModal, setShowConfirmFinishedModal] = useState(false);
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [isTicketFinished, setIsTicketFinished] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [message, setMessage] = useState('');
    const [filesCustomer, setFilesCustomer] = useState<(File | UniInputFileReference)[]>([]);
    const [filesUnipar, setFilesUnipar] = useState<UniInputFileReference[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSendMessage, setLoadingSendingMessage] = useState<boolean>(false);
    const [fileIsRemoving, setFileIsRemoving] = useState<boolean>(false);
    const [fileIsAdding, setFileIsAdding] = useState<boolean>(false);
    
    const navigate = useNavigate();
    const { t, i18n,  } = useTranslation();

    useEffect(() => {
        getTicketData();
    }, []);

    const getTicketData = () => {
        setLoading(true);
        getTicket(searchParams.get('id') ?? '')
        .then(m => {
            if (m.success) {
                const messageHistory: ResponseTicketHistory = {
                    id: '',
                    createdAt: m.data.createdAt,
                    description: m.data.description,
                    isUnipar: false
                }
                m.data.histories.splice(0, 0, messageHistory);
                setData(m.data);
                const filesIsCustomer = m.data.files.filter(x => !x.isUnipar);
                const filesCustomerToAdd: UniInputFileReference[] = [];
                filesIsCustomer.map(file => {
                    try {
                        const fileReference : UniInputFileReference = {
                            id: file.id,
                            name: file.name,
                            type: getMimeTypeFromExtension(file.name.split('.')[1]),
                            url: file.urlBlob
                        }
                        filesCustomerToAdd.push(fileReference);
                        setFilesCustomer([...filesCustomerToAdd]);
                    } catch (error) {
                        console.error(error);
                    }
                });                
                const filesIsUnipar = m.data.files.filter(x => x.isUnipar);
                const filesUniparToAdd: UniInputFileReference[] = [];
                filesIsUnipar.map(async (file) => {
                    try {
                        const fileReference : UniInputFileReference = {
                            id: file.id,
                            name: file.name,
                            type: getMimeTypeFromExtension(file.name.split('.')[1]),
                            url: file.urlBlob
                        }
                        filesUniparToAdd.push(fileReference);
                        setFilesUnipar([...filesUniparToAdd]);
                    } catch (error) {
                        console.error(error);
                    }
                });
                setIsTicketFinished(m.data.status ===  TicketStatusEnum.Finished || m.data.status ===  TicketStatusEnum.Pending);
            } else {                
                setErrorModal(
                    (i18n.language === 'pt' ? m.errorPT :
                    i18n.language === 'es' ? m.errorES :
                    m.errorEN ) ?? ''
                );
                setShowErrorModal(true);
                setErrorModalTitle(t('errorSearchMessage'));
            }
        })
        .catch(e => {
            console.log(e);
            setErrorModal(e);
            setErrorModalTitle(t('errorSearchMessage'))
            setShowErrorModal(true);
        })
        .finally(() => setLoading(false));
    }
    
    const onRemoveFilesCustomer = (file : (File | UniInputFileReference)) => {
        setFileIsRemoving(true);
        const fileId = file instanceof File ? '': file.id;
        if(fileId !== '') {
            removeTicketFile(fileId)
            .then(m => {
                if (!m.success) {                    
                    setErrorModal(
                        (i18n.language === 'pt' ? m.errorPT :
                        i18n.language === 'es' ? m.errorES :
                        m.errorEN ) ?? ''
                    );
                    setShowErrorModal(true);
                    setErrorModalTitle(t('errorRemoveMessage'));
                }
            })
            .catch(e => {
                console.log(e);
                setErrorModal(e);
                setErrorModalTitle(t('errorRemoveMessage'))
                setShowErrorModal(true);
            })
            .finally(() => setFileIsRemoving(false));
        } else {
            setFileIsRemoving(false);
        }
    }

    const onAddFilesCustomer = (filesToAdd: File[]) => {
        setFileIsAdding(true);
        return addTicketFile(data?.id ?? '', filesToAdd)
        .then(m => {
            if (m.success) {
                getTicketData();
            } else {
                setErrorModal(
                    (i18n.language === 'pt' ? m.errorPT :
                    i18n.language === 'es' ? m.errorES :
                    m.errorEN ) ?? ''
                );
                setShowErrorModal(true);
                setErrorModalTitle(t('errorSearchMessage'));
            }
        })
        .catch(e => {
            console.log(e);
            setErrorModal(e);
            setErrorModalTitle(t('errorSearchMessage'))
            setShowErrorModal(true);
        })
        .finally(() => setFileIsAdding(false));
    }    
    
    const onChangeMessage = (e: ChangeEvent<FormControlElement>) => {
        setMessage(e?.target?.value);
    }

    const validate = () => {
        setMessageError(false);

        let isValid = true;
        
        if(!message) {
            isValid = false;
            setMessageError(true);
        }

        return isValid;
    }

    const onSaveMessage = () => {        
        if(!validate()) {
            return
        }

        setLoadingSendingMessage(true);
        createTicketReply(data?.id ?? '', message).then(m => {
            if (m.success) {
                setShowButtons(true);
                setMessage('');
                data?.histories?.push(m.data);
                const pendingTicket : AlterTicket = {
                    status: TicketStatusEnum.Pending,
                    sectorId: data?.sector?.id ?? ''
                };
                return alterTicket(data?.id ?? '', pendingTicket, i18n.language.toUpperCase()).then(m => {
                    if (m.success) {
                        setData({...data, status: TicketStatusEnum.Pending});                        
                        setIsTicketFinished(true);
                    } else {
                        setErrorModal(
                            (i18n.language === 'pt' ? m.errorPT :
                            i18n.language === 'es' ? m.errorES :
                            m.errorEN ) ?? ''
                        );
                        setShowErrorModal(true);
                        setErrorModalTitle(t('callCenter.errorMessage'));
                    }
                })
            } else {                
                setErrorModal(
                    (i18n.language === 'pt' ? m.errorPT :
                    i18n.language === 'es' ? m.errorES :
                    m.errorEN ) ?? ''
                );
                setShowErrorModal(true);
                setErrorModalTitle(t('callCenter.errorMessage'));
            }
        })
        .catch(e => {
            console.log(e);
            setShowErrorModal(true);
            setErrorModalTitle(t('callCenter.errorMessage'));
            setErrorModal(e);
        })
        .finally(() => setLoadingSendingMessage(false));
    }

    const onFinishTicket = () => {
        setShowConfirmFinishedModal(true);
    }

    const onConfirmFinishTicket = () => {
        setShowConfirmFinishedModal(false);        

        setLoadingSendingMessage(true);
        const finishTicket : AlterTicket = {
            status: TicketStatusEnum.Finished,
            sectorId: data?.sector?.id ?? ''
        };
        alterTicket(data?.id ?? '', finishTicket, i18n.language.toUpperCase()).then(m => {
            if (m.success) {
                setIsTicketFinished(true);
                setShowFinishedModal(true);
                setData({...data, status: TicketStatusEnum.Finished});
            } else {                
                setErrorModal(
                    (i18n.language === 'pt' ? m.errorPT :
                    i18n.language === 'es' ? m.errorES :
                    m.errorEN ) ?? ''
                );
                setShowErrorModal(true);
                setErrorModalTitle(t('callCenter.errorFinishMessage'));
            }
        })
        .catch(e => {
            console.log(e);
            setShowErrorModal(true);
            setErrorModalTitle(t('callCenter.errorFinishMessage'));
            setErrorModal(e);
        })
        .finally(() => setLoadingSendingMessage(false));
    }

    return (
        <>
            <div className='call-center-view-wrapper'>
                <div className='call-center-view-header w-100'>
                    <div className='call-center-view-header-i'>
                        <img src={callCenter} alt='icone de atendimento' />
                        {t('callCenter.viewTicket')}
                    </div>
                    <button className='header-back-button' onClick={() => navigate('/call-center')}>
                        {t('back')}
                    </button>
                </div>                
                <div className='w-100 d-flex justify-content-center body-wrapper'>
                    <div className='d-flex flex-column w-100'>
                        <div className='row'>
                            {data?.status === TicketStatusEnum.Served && (
                                <div className='col served-warning-message'>
                                    <img src={warningBlack} alt='warning'/>
                                    {t('callCenter.servedWarningMessage', {daysToFinishTicket: daysToFinishTicket})}
                                </div>
                            )}
                        </div>
                        <div className='row row-reverse'>
                            <div className='col-md-6 no-padding first-column'>
                                <p className='form-title-left'>                                            
                                    {t('callCenter.viewHistoryPageTitle')}
                                </p>
                                <div className='padded-block-left'>
                                    {loading ? (
                                        <Skeleton
                                            width={'100%'}
                                            height={600}
                                            duration={1}
                                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                            baseColor={darkMode ? '#021717' : '#ebebeb'} 
                                        />
                                    ) : (
                                        <>
                                            {
                                                data?.histories?.map((x, idx) => (
                                                    <div className='history'>
                                                        {idx > 0 && (
                                                            <div className='divider' />
                                                        )}
                                                        <div className='history-wrapper'>
                                                            {x.isUnipar ? (
                                                                <img src={ticketHistoryUniparIcon} className='history-icon' alt='unipar icon'/>
                                                            ) : (
                                                                <img src={ticketHistoryUserIcon} className='history-icon' alt='user icon'/>
                                                            )}
                                                            <div className={'history-body ' + (x.isUnipar ? 'is-unipar' : '')}>
                                                                <div className='history-header'>
                                                                    <div className='history-title'>
                                                                    {
                                                                        x.isUnipar ? t('callCenter.uniparReply') : t('callCenter.userMessage')
                                                                    }
                                                                    </div>
                                                                    <div className='history-date'>{moment(x.createdAt).format('DD/MM/yyyy HH:mm')}</div>
                                                                </div>
                                                                <p className='history-description'>{x.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {
                                                !isTicketFinished && (
                                                    showButtons ? (
                                                        <div className='buttons-wrapper'>
                                                            <button className='button-reply' onClick={() => {
                                                                setShowButtons(false);
                                                            }}>
                                                                {t('callCenter.addReply')}
                                                            </button>
                                                            <button 
                                                                className='button-finish'
                                                                disabled={loadingSendMessage}
                                                                onClick={() => onFinishTicket()} 
                                                            >                                                        
                                                                {loadingSendMessage ? <Spinner/> : t('callCenter.finishTicket')}
                                                            </button>                                                    
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <Form.Group className={'message mb-3 ' + (messageError ? 'input-error' : '')}>
                                                                <Form.Label>{t('callCenter.labelDescription')}</Form.Label>
                                                                <Form.Control 
                                                                    as='textarea'
                                                                    rows={4} 
                                                                    maxLength={700}
                                                                    placeholder={t('callCenter.labelDescriptionPlaceholder')}
                                                                    value={message} 
                                                                    onChange={(e) => onChangeMessage(e)}
                                                                    disabled={loadingSendMessage}
                                                                />
                                                            </Form.Group>
                                                            {messageError && (
                                                                <p className='error-mandatory'>{t('fieldErrorMandatoryMessage')}</p>
                                                            )}
                                                            <div className='buttons-wrapper'>
                                                                <button 
                                                                    className='button-send'
                                                                    disabled={loadingSendMessage} 
                                                                    onClick={() => onSaveMessage()}
                                                                >
                                                                    {loadingSendMessage ? <Spinner/> : t('send')}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )
                                                )
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='col-md-6 no-padding second-column'>
                                <p className='form-title-right'>
                                    {t('callCenter.viewDetailsPageTitle')}
                                </p>
                                <div className='padded-block-right'>
                                    {loading ? (
                                        <Skeleton
                                            width={'100%'}
                                            height={400}
                                            duration={1}
                                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                            baseColor={darkMode ? '#021717' : '#ebebeb'} 
                                        />
                                    ) : (
                                        <>                                        
                                            <div>
                                                <label>
                                                    Status
                                                </label>
                                                <div style={{ minWidth: 'fit-content', width: 165 }}>                                                    
                                                    <div className='status' style={{ backgroundColor: callCenterStatusStyle.filter(s => s.name === data?.status)?.[0]?.bgColor ?? '#fff' }}>
                                                        <span className='status-icon' style={{ backgroundColor: callCenterStatusStyle.filter(s => s.name === data?.status)?.[0]?.color ?? '#fff' }}></span>
                                                        <div className='w-100' style={{ fontSize: 12, fontWeight: 700, minWidth: 'fit-content' }}>{getTicketDescriptionStatus(data?.status ?? TicketStatusEnum.Pending)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='divider' />
                                            <div className='row'>
                                                <div className='col'>
                                                    <label>
                                                        {t('callCenter.number')}
                                                    </label>
                                                    <p className='property-value'>{data?.number}</p>
                                                </div>
                                                <div className='col'>
                                                    <label>
                                                        {t('createdAt')}
                                                    </label>
                                                    <p className='property-value'>{moment(data?.createdAt).format('DD/MM/yyyy HH:mm')}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <label>
                                                        {t('callCenter.sector')}
                                                    </label>
                                                    <p className='property-value'>{
                                                        i18n.language === 'pt' ? data?.sector?.namePT : 
                                                        (i18n.language === 'es' ? data?.sector?.nameES :
                                                        data?.sector?.nameEN )
                                                    }</p>
                                                </div>
                                                <div className='col'>
                                                    <label>
                                                        {t('callCenter.labelSubject')}
                                                    </label>
                                                    <p className='property-value'>{
                                                        i18n.language === 'pt' ? data?.ticketLevel?.descriptionPT : 
                                                        (i18n.language === 'es' ? data?.ticketLevel?.descriptionES :
                                                        data?.ticketLevel?.descriptionEN )
                                                    }</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <label>
                                                        {t('callCenter.labelPhone')}
                                                    </label>
                                                    <p className='property-value'>{data?.telephone}</p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {loading ? (
                                        <Skeleton
                                            width={'100%'}
                                            height={180}
                                            duration={1}
                                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                            baseColor={darkMode ? '#021717' : '#ebebeb'} 
                                        />
                                    ) : (
                                        <>
                                        		{(filesUnipar.length > 0 || filesCustomer.length > 0) && (
                                                <div className='divider' />
                                            )}
                                            {(filesUnipar?.length ?? 0) > 0 && (
                                                <>
                                                    <div>
                                                        <UniInputFile
                                                            canEdit={false}
                                                            files={filesUnipar}
                                                            label={t('callCenter.attachmentsUnipar')}
                                                            fileExtensions={['.jpg', '.png', '.pdf']}
                                                            fileRemoveModalTitle={t('fileRemoveModalTitle')}
                                                            fileRemoveConfirmation={false}
                                                        />
                                                    </div>
                                                    <div className='divider' />
                                                </>
                                            )}
                                            <div>
                                                <UniInputFile
                                                    canEdit={!isTicketFinished}
                                                    files={filesCustomer}
                                                    label={t('callCenter.attachmentsCustomer')}
                                                    fileExtensions={['.jpg', '.png', '.pdf']}
                                                    onFileAdd={files => onAddFilesCustomer(files)}
                                                    onFileRemove={file => onRemoveFilesCustomer(file)}
                                                    fileRemoveModalTitle={t('fileRemoveModalTitle')}
                                                    fileIsAdding={fileIsAdding}
                                                    fileIsRemoving={fileIsRemoving}
                                                    fileRemoveConfirmation={true}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>           
            <UniModal
                icon={faXmark}
                isFontAwsome
                iconColor='#EA2027'
                title={errorModalTitle}
                message={''}
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
            >    
                <p>{errorModal}</p>
                <button 
                    className='button-close no-margin' 
                    onClick={() => setShowErrorModal(false)}>
                    {t('close')}
                </button>
            </UniModal>
            <UniModal
                icon={circleCheckMarkGreen}
                title={t('callCenter.finishedConfirmMessage')}
                message={''}
                show={showConfirmFinishedModal}
                onHide={() => {
                    setShowConfirmFinishedModal(false);
                    setShowFinishedModal(true);
                }}
            >    
                <button 
                    className='button-close' 
                    onClick={() => setShowConfirmFinishedModal(false)}>
                    {t('close')}
                </button>
                <button 
                    className='button-confirm' 
                    onClick={() => onConfirmFinishTicket()}>
                    {t('confirm')}
                </button>
            </UniModal>
            <UniModal
                icon={circleCheckMarkGreen}
                title={t('callCenter.finishedMessage')}
                message={''}
                show={showFinishedModal}
                onHide={() => setShowFinishedModal(false)}
            >    
                <button 
                    className='button-close no-margin' 
                    onClick={() => setShowFinishedModal(false)}>
                    {t('close')}
                </button>
            </UniModal>
        </>
    );
}

export default CallCenterView;