import './UniWidgetMyOrders.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSort, faXmark, faArrowsRotate, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { useUserStore } from '../../store/user';
import { useEffect, useState } from 'react';
import { OrderCancellationRequestModel, ResponseOrderIntentShipment, cancelOrderIntent, getOnComingOrdersIntentsShipments } from '../../services/orderService';
import { StatusIntent } from '../../types/orderIntent';
import moment from 'moment';
import { getDescriptionStatusIntent, getDescriptionStatusIntentLanguage, getTranslateIdStatusIntent } from '../../helpers/enums';
import { faCheckCircle, faFaceFrownOpen, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getMaterialHasPrice } from '../../services/materialService';
import UniModal from '../UniModal/UniModal';
import { Button, Spinner } from 'react-bootstrap';
import { Profile } from '../../services/userService';
import { useWindowSize } from "@uidotdev/usehooks";
import { useTranslation } from 'react-i18next';
import { writeFobOrFcaOrDate } from '../../helpers/utils';

type UniWidgetMyOrders = {
    materialCode?: string | null
}
const UniWidgetMyOrders = ({ materialCode }: UniWidgetMyOrders) => {
    const { darkMode, selectedCustomer, loginData } = useUserStore();
    const [loading, setLoading] = useState<boolean>(false);
    const [notFound, setNotfoud] = useState<boolean>(false);
    const [showRebuyError, setShowRebuyError] = useState<boolean>(false);
    const [showModalCancelOrderIntent, setShowModalCancelOrderIntent] = useState(false);
    const [cancelId, setCancelId] = useState('');
    const [error, setError] = useState<string|undefined>('');
    const [msgOkCancelOrderIntent, setMsgOkCancelOrderIntent] = useState('');
    const [msgErrorCancelOrderIntent, setMsgErrorCancelOrderIntent] = useState('');
    const [ordersShipments, setOrders] = useState<ResponseOrderIntentShipment[]>();
    const size = useWindowSize();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (materialCode) getOrders();
    }, [materialCode])

    const getOrders = () => {
        const month = moment().month() + 1;
        setError('');
        setLoading(true);
        getOnComingOrdersIntentsShipments(20, 1, selectedCustomer.issuer, materialCode ?? '', month.toString(), moment().year().toString())
            .then(response => {
                if (!response.success) {
                    var error = response.error ?? '';

                    if(i18n.language.toUpperCase() === 'PT')
                        error = response.errorPT ?? '';
                    if(i18n.language.toUpperCase() === 'EN')
                        error = response.errorEN ?? '';
                    if(i18n.language.toUpperCase() === 'ES')
                        error = response.errorES ?? '';
                    setError(error);
                    setOrders([]);
                }
                else {
                    setOrders(response.data);
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const cancelOrders = () => {
        var orderIds = [];
        if (!cancelId) return;

        orderIds.push(cancelId)

        let request: OrderCancellationRequestModel = {
            orderIds: orderIds,
            issuerCode: selectedCustomer?.issuer,
            reasonCancelation: 'Canceled by customer'
        }

        setLoading(true);
        cancelOrderIntent(request)
            .then(response => {
                if (response.success)
                    setMsgOkCancelOrderIntent('modalErrorCancelFeedbackOk');
                else
                    setMsgErrorCancelOrderIntent('modalErrorCancelFeedbackErr');
            })
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false);
                getOrders();
                setCancelId('');
                setShowModalCancelOrderIntent(false);
            })

    }

    const mockOrders: any[] = [
        {
            numPedido: '701-2496262',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Análise Unipar',
        },
        {
            numPedido: '701-249662',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Faturados',
        },
        {
            numPedido: '701-249662',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Análise Unipar',
        },
        {
            numPedido: '701-249662',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Análise Unipar',
        },
        {
            numPedido: '701-249662',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Análise Unipar',
        },
        {
            numPedido: '701-249662',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Análise Unipar',
        },
        {
            numPedido: '701-249662',
            dtEntrega: '01/06/2023',
            quantidade: '10 t',
            status: 'Análise Unipar',
        },
    ];

    const mockDataTable = {
        headers: [
            {
                property: 'customerOrderNumber',
                name: t('homeWidgets.tdOrderNumber'),
                translateId: 'tdOrderNumber'
            },
            {
                property: 'shipmentDate',
                name: t('homeWidgets.tdShipmentDate'),
                isDate: true,
                translateId: 'tdShipmentDate'
            },
            {
                property: 'deliveryDate',
                name: t('homeWidgets.tdDeliveryDate'),
                isDate: true,
                translateId: 'tdDeliveryDate'

            },
            {
                property: 'shipmentQuantity',
                name: t('homeWidgets.tdShipmentQtd'),
                translateId: 'tdShipmentQtd'
            },
            {
                property: 'status',
                name: t('homeWidgets.tdStatus'),
                isStatus: true,
                translateId: 'tdStatus'
            },
            {
                property: 'actions',
                name: t('homeWidgets.tdActions'),
                translateId: 'tdActions'
            },
        ]
    };

    const mockStatusStyle = [
        {
            name: StatusIntent.UniparValidation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
        {
            name: StatusIntent.CustomerPendency,
            bgColor: '#FFEFEA',
            color: '#FF6636',
        },
        {
            name: StatusIntent.OrderConfirmed,
            bgColor: '#FFE7FD',
            color: '#FF35EB',
        },
        {
            name: StatusIntent.Arrived,
            bgColor: '#FFF6C7',
            color: '#DFBC07',
        },
        {
            name: StatusIntent.EntryWeight,
            bgColor: '#F4EDFF',
            color: '#9E7BD5',
        },
        {
            name: StatusIntent.LoadAndSealed,
            bgColor: '#CFFFFC',
            color: '#0BE3D6',
        },
        {
            name: StatusIntent.Invoiced,
            bgColor: '#EEFFF2',
            color: '#30C10C',
        },
        {
            name: StatusIntent.Recused,
            bgColor: '#ffcfcf',
            color: '#e35b5b',
        },
        {
            name: StatusIntent.Canceled,
            bgColor: '#f7dcde',
            color: '#db2131',
        },
        {
            name: StatusIntent.EditSolicitation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
        {
            name: StatusIntent.ReviewCancelation,
            bgColor: '#f7dcde',
            color: '#db2131',
        }
    ];

    const rebuy = async (materialCode: string, order: any) => {
        const response = await getMaterialHasPrice(selectedCustomer.issuer, materialCode);
        if (response.data) {
            navigate(`/rebuy/${order?.orderIntentGroupId}?idOrder=${order?.id}`);
        } else {
            setShowRebuyError(true);
        }
    }

    if (loading) {
        return (
            <Skeleton
                height={325}
                width={'100%'}
                style={{ marginTop: 30, marginRight: 27 }}
                highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                baseColor={darkMode ? '#021717' : '#ebebeb'} />
        )
    }

    const getNextUtilDate = (date: Date) => {
        const initialDate = new Date(date);
        while (initialDate.toDateString() === date.toDateString() || date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    const isValidDate = (e?: any): boolean => {
        const date = new Date(e);
        if (!date) return false;

        if (date.toDateString() === new Date().toDateString()) {
            return false;
        }

        if (date < new Date()) {
            return false;
        }

        const dateNow = new Date();
        if ((dateNow.getHours() >= 17 || dateNow.getDay() === 0 || dateNow.getDay() === 6) && date.toDateString() === getNextUtilDate(dateNow).toDateString()) {
            return false;
        }

        const dateNow4 = new Date();
        if (dateNow.getHours() >= 11 && date.toDateString() === getNextUtilDate(dateNow4).toDateString()) {
            return false;
        }

        const dateNow3 = new Date();
        const nextDay = new Date(dateNow3.getFullYear(), dateNow3.getMonth(), dateNow3.getDate() + 1);
        if (dateNow.getHours() >= 11 && date.toDateString() === nextDay.toDateString()) {
            return false;
        }

        const dateNow2 = new Date();
        const dateNowT = new Date();

        var date1 = new Date(date.toDateString());
        var date2 = new Date(dateNowT.toDateString());

        var diffTime = date1.getTime() - date2.getTime();
        var diffDays = diffTime / (1000 * 3600 * 24);
        if (dateNow2.getDay() === 5 && dateNow2.getHours() >= 11 && (date.getDay() === 0 || date.getDay() === 6) && diffDays < 7) {
            return false;
        }

        return true;
    }

    return (
        <>
            <table className='table-uni'>
                <thead>
                    <tr>
                        {
                            mockDataTable.headers.map(header => (
                                <td key={header.name} style={header.isStatus ? (size?.width && size?.width < 910) ? { width: 80 } : { width: 180 } : {}}>

                                    {header.property === 'actions' ? (loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) && t(`homeWidgets.${header.translateId}`) : t(`homeWidgets.${header.translateId}`)}
                                    {/* <FontAwesomeIcon icon={faSort} /> */}
                                </td>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        ordersShipments?.map((order: any, idx) => (
                            <tr key={idx.toString()}>
                                {
                                    mockDataTable.headers.map(header => {
                                        if (header.property === 'actions' && (loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) && parseFloat(order?.shipmentQuantity) >= 0) {
                                            return (
                                                <td key={header.name} className='table-actions'>
                                                    <div title={t('myOrders.rebuy')} className='table-icon' onClick={() => { rebuy(order?.materialCode, order) }}>
                                                        <FontAwesomeIcon icon={faArrowsRotate} />
                                                    </div>
                                                    {
                                                        order?.status === StatusIntent.CustomerPendency ? (
                                                            <div
                                                                title={t('myOrders.aproveChanges')}
                                                                className={order.status == StatusIntent.CustomerPendency ? 'table-icon' : 'table-icon-disabled'}
                                                                onClick={() => { if (order?.status === StatusIntent.CustomerPendency) { navigate(`/approve/${order?.orderIntentGroupId}`) } }}
                                                            >
                                                                <FontAwesomeIcon icon={faSquareCheck} />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                title={t('myOrders.edit')}
                                                                className={(order?.status === StatusIntent.UniparValidation ||
                                                                    order?.status === StatusIntent.OrderConfirmed) &&
                                                                    isValidDate(order?.shipmentDate ?? order?.deliveryDate) ? 'table-icon' : 'table-icon-disabled'}
                                                                onClick={() => {
                                                                    if ((order?.status === StatusIntent.UniparValidation ||
                                                                        order?.status === StatusIntent.OrderConfirmed) &&
                                                                        isValidDate(order?.shipmentDate ?? order?.deliveryDate)) {
                                                                        navigate(`/edit-order/${order?.orderIntentGroupId}?idOrder=${order?.id}`)
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPenToSquare} />
                                                            </div>
                                                        )
                                                    }
                                                    <div
                                                        title={t('myOrders.cancel')}
                                                        className={(order?.status === StatusIntent.UniparValidation ||
                                                            order?.status === StatusIntent.OrderConfirmed ||
                                                            order?.status === StatusIntent.EditSolicitation) &&
                                                            isValidDate(order?.shipmentDate ?? order?.deliveryDate) ? 'table-icon' : 'table-icon-disabled'}
                                                        onClick={() => {
                                                            if ((order?.status === StatusIntent.UniparValidation ||
                                                                order?.status === StatusIntent.OrderConfirmed ||
                                                                order?.status === StatusIntent.EditSolicitation) &&
                                                                isValidDate(order?.shipmentDate ?? order?.deliveryDate)) {
                                                                setShowModalCancelOrderIntent(true);
                                                                setCancelId(order.id)
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </div>
                                                </td>
                                            );
                                        }

                                        if (header.property === 'shipmentQuantity') {
                                            return (
                                                <td key={header.name}>{order?.[header.property] ? order?.[header.property]?.toString().replace('.', ',') ?? '' : ''} t</td>
                                            )
                                        }

                                        if (header.property === 'customerOrderNumber') {
                                            return (
                                                <td key={header.name} title={order?.[header.property]}>{order?.[header.property]?.length > 13 ? (order?.[header.property]?.slice(0, 12) + '...' ?? '-') : order?.[header.property] ?? '-'}</td>
                                            )
                                        }

                                        if (header.isDate && header.property === "shipmentDate") {
                                            return (
                                                <td key={header.name}>{order?.[header.property] ? moment(order?.[header.property]).format('DD/MM/yyyy') : '-'}</td>
                                            )
                                        }

                                        if (header.isDate&& header.property === "deliveryDate") {
                                            return (
                                                <td key={header.name}>{writeFobOrFcaOrDate(order?.['incoterms'], order?.[header.property] ? moment(order?.[header.property]).format('DD/MM/yyyy') : '-')}</td>
                                            )
                                        }

                                        if (header.isStatus) {
                                            return (
                                                <td key={header.name} width={(size?.width && size?.width < 910) ? 80 : 180}>
                                                    <div className='table-status' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.bgColor ?? '#fff' }}>
                                                        <span className='table-status-icon' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.color ?? '#fff' }}></span>
                                                        {t(`homeWidgets.${getTranslateIdStatusIntent(order?.[header.property])}`) ?? (getDescriptionStatusIntentLanguage(order?.[header.property],i18n.language.toUpperCase()) ?? '')}
                                                    </div>
                                                </td>
                                            );
                                        }

                                        return (
                                            <td key={header.name} title={order?.[header.property] ?? '-'}>{order?.[header.property]?.length > 10 ? (order?.[header.property]?.slice(0, 9) + '...' ?? '-') : order?.[header.property] ?? '-'}</td>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('homeWidgets.modalErrorRebuyTitle')}
                message=''
                iconColor='#EA2027'
                show={showRebuyError}
                onHide={() => {
                    setShowRebuyError(false)
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('homeWidgets.modalErrorRebuyMsg')}</p>
                <Button variant='primary' onClick={() => navigate('/new-order')}>{t('homeWidgets.modalErrorRebuyBtn')}</Button>
            </UniModal>

            <UniModal
                icon={''}
                isFontAwsome={true}
                title={t('homeWidgets.modalErrorCancelTitle')}
                message=''
                iconColor='#EA2027'
                show={showModalCancelOrderIntent}
                onHide={() => {
                    setShowModalCancelOrderIntent(false)
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('homeWidgets.modalErrorCancelMsg')}</p>
                {loading ?
                    (<Spinner style={{ color: '#027948', width: 25, height: 25 }} />)
                    :
                    (
                        <>
                            <Button id='btnCancelOrder' style={{ backgroundColor: '#FF0000' }} variant='primary' onClick={() => cancelOrders()}>{t('homeWidgets.modalErrorCancelBtnY')}</Button>&nbsp;
                            <Button variant='primary' onClick={() => setShowModalCancelOrderIntent(false)}>{t('homeWidgets.modalErrorCancelBtnN')}</Button>
                        </>
                    )
                }
            </UniModal>

            <UniModal
                icon={faCheckCircle}
                isFontAwsome
                title={t('homeWidgets.modalErrorCancelTitle')}
                message=''
                iconColor='#EA2027'
                show={msgOkCancelOrderIntent !== ''}
                onHide={() => {
                    setMsgOkCancelOrderIntent('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('homeWidgets.modalErrorCancelFeedbackOk')}</p>
                <Button variant='primary' onClick={() => setMsgOkCancelOrderIntent('')}>{t('homeWidgets.modalErrorCancelBtnClose')}</Button>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('homeWidgets.modalErrorCancelTitle')}
                message=''
                iconColor='#EA2027'
                show={msgErrorCancelOrderIntent !== ''}
                onHide={() => {
                    setMsgErrorCancelOrderIntent('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('homeWidgets.modalErrorCancelFeedbackErr')}</p>
                <Button variant='primary' onClick={() => setMsgErrorCancelOrderIntent('')}>{t('homeWidgets.modalErrorCancelBtnClose')}</Button>
            </UniModal>
        </>
    );
}

export default UniWidgetMyOrders;