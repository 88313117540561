import '../styles/CallCenter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortAmountDown, faSortAmountUp, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Button } from 'react-bootstrap';
import callCenter from '../assets/icons/call-center.svg';
import newCallCenter from '../assets/icons/new-call-center.svg';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import UniPagination from '../components/UniPagination/UniPagination';
import { Response, getData, TicketStatusEnum, getSectors, ResponseSector, ResponseTicketLevel, getTicketLevels } from '../services/callCenterService';
import { useUserStore } from '../store/user';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import UniModal from '../components/UniModal/UniModal';
import { useTranslation } from "react-i18next";
import { useWindowSize } from '@uidotdev/usehooks';
import UniDatePickerRange from '../components/UniDatePickerRange/UniDatePickerRange';
import { callCenterStatusStyle, getTicketDescriptionStatus } from '../helpers/enums';

type SearchForm = {
    number?: string,
    ticketLevel?: string,
    status?: string
};

var sortField = '';

const CallCenter = () => {
    const [data, setData] = useState<Response[]>();
    const [ticketLevels, setTicketLevels] = useState<ResponseTicketLevel[]>();
    const [search, setSearch] = useState<SearchForm>();    
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModal, setErrorModal] = useState('');
    const [loading, setLoading] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>();
    const [totalItems, setTotalItems] = useState<number>();
    const [take, setTake] = useState<any>(10);
    const [page, setPage] = useState<number>(0);    
    const [showOpened, setShowOpened] = useState(false);
    const { darkMode, selectedCustomer } = useUserStore();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();    
    const size = useWindowSize();
    const [initialDate, setInitialDate] = useState<Date | any>(undefined);
    const [finalDate, setFinalDate] = useState<Date | any>(undefined);    
    const [periodValue, setPeriodValue] = useState<string | null>('');

    const dataTable = {
        headers: [
            {
                index: 0,
                property: 'number',
                name: `${t('callCenter.number')}`
            },
            {
                index: 1,
                property: 'createdAt',
                name: `${t('createdAt')}`,
                isDate: true
            },
            {
                index: 2,
                property: 'ticketLevel',
                name: `${t('callCenter.labelSubject')}`
            },
            {
                index: 3,
                property: 'status',
                name: 'Status',
                isStatus: true
            },
            {
                index: 4,
                property: 'actions',
                name: '',
            },
        ]
    };

    useEffect(() => {
        getTickets();
    }, [take, page]);

    useEffect(() => {
        setLoading(true);
        getTicketLevels()
            .then(m => {
                if (m.success) {
                    setTicketLevels(m.data);                    
                } else {                
                    setErrorModal(
                        (i18n.language === 'pt' ? m.errorPT :
                        i18n.language === 'es' ? m.errorPT :
                        m.errorEN ) ?? ''
                    );
                    setShowErrorModal(true);
                }
            })
            .catch(e => {
                console.log(e);
                setShowErrorModal(true);                
                setErrorModal(e);
            })
            .finally(() => setLoading(false));
    }, [])

    const onChangeDates = (dates: any) => {
        const [start, end] = dates;
        setPeriodValue(dateRange(start,end));
        setInitialDate(start);
        setFinalDate(end);
        return;
    };

    const dateRange = (start: any, end: any) => {
        return moment(start).format('DD/MM/yyyy') + ' - ' + moment(end).format('DD/MM/yyyy');
    }

    const getTickets = () => {
        let initialDateParam = initialDate;
        let finalDateParam = finalDate;

        if (typeof(initialDate) === 'object' && typeof(finalDate) === 'object'){
            initialDateParam = moment(initialDate).format('yyyy-MM-DD');
            finalDateParam = moment(finalDate).format('yyyy-MM-DD');
        }

        setLoading(true);
        getData(take, page, selectedCustomer.issuer, search?.number, search?.ticketLevel ?? '', search?.status ?? '', sortField, i18n.language.toUpperCase(), initialDateParam, finalDateParam)
            .then(response => {
                if (!response.success) {
                    setErrorModal(
                        (i18n.language === 'pt' ? response.errorPT :
                        i18n.language === 'es' ? response.errorES :
                        response.errorEN ) ?? ''
                    );
                    setShowErrorModal(true);
                }
                else {
                    setData(response.data);
                    setTotalItems(response.totalItems);
                    setTotalPages(response.totalPages);
                }
            })
            .catch(e => {
                console.log(e);
                setShowErrorModal(true);                
                setErrorModal(e);
            })
            .finally(() => setLoading(false))
    }    

    const onChangeNumber = (e: ChangeEvent<FormControlElement>) => {
        const value = e?.target?.value.replace(/[^0-9]/, '');
        setSearch({ ...search, number: value });
    }

    const onChangeTicketLevel = (e: ChangeEvent<HTMLSelectElement>) => {
        setSearch({ ...search, ticketLevel: e?.target?.value });
    }

    const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
        setSearch({...search, status: e?.target?.value });
    }

    const sortList = (property: string) => {
        var sort = '';
        if(sortField.indexOf(property)>=0)
        {
            if(sortField.indexOf('=asc')>0)
                sort = property + '=desc'
            else
                sort = property + '=asc'
        }
        else
            sort = property + '=asc'

        sortField = sort;

        getTickets();
    }

    const gotoTicket = (id: string) => {
        navigate(`/call-center/view?id=${id}`);
    }

    const getTicketLevelDescription = (ticketLevel: ResponseTicketLevel) => {
        return (i18n.language === 'pt' ? ticketLevel?.descriptionPT :
            i18n.language === 'es' ? ticketLevel?.descriptionES :
            ticketLevel?.descriptionEN ) ?? ''
    }

    const MobileGrid = () => (
        <Accordion as={'div'}>
            {
                data?.map((item: any, idx) => (
                    <Accordion.Item as={'div'} eventKey={idx.toString()} key={idx.toString()}>
                        <div className='item-header flex-column'>
                            <div className='d-flex gap-3'>
                                {
                                    dataTable.headers.map((header, idxh) => {
                                        if (header.isStatus) {
                                            return (
                                                <div key={idxh.toString() + idx.toString()} style={{ minWidth: 'fit-content', width: 165 }}>
                                                    <div className='table-status' style={{ backgroundColor: callCenterStatusStyle.filter(s => s.name === item.status)?.[0]?.bgColor ?? '#fff', minWidth: 145 }}>
                                                        <span className='table-status-icon' style={{ backgroundColor: callCenterStatusStyle.filter(s => s.name === item.status)?.[0]?.color ?? '#fff' }}></span>
                                                        <div className='w-100' style={{ fontSize: 12, fontWeight: 700, minWidth: 'fit-content' }}>{getTicketDescriptionStatus(item?.[header.property])}</div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>

                            {
                                dataTable.headers.map((header, idxh) => {
                                    if (header.isDate) {
                                        return (
                                            <div className='d-flex'>
                                                <div key={idxh.toString() + idx.toString()}><span>{t(header.name)}: </span> {item?.[header.property] ? moment(item?.[header.property]).format('DD/MM/yyyy HH:mm') : '-'}</div>
                                            </div>
                                        )
                                    } else if (header.property === 'actions') {
                                        return (
                                            <div className='action'>
                                                <div
                                                    className='button-icon'
                                                    onClick={() => gotoTicket(item.id)}
                                                >
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </div>
                                            </div>
                                        )
                                    } else if (header.property === 'ticketLevel') {
                                        return (
                                            <div className='d-flex gap-3'>                                                
                                                <div key={idxh.toString() + idx.toString()}><span>{t(header.name)}:</span> {getTicketLevelDescription(item?.ticketLevel)}</div>
                                            </div>
                                        )
                                    } else if (header.property !== 'status') {
                                        return (
                                            <div className='d-flex gap-3'>
                                                <div key={idxh.toString() + idx.toString()} title={item?.[header.property] ?? '-'}><span>{t(header.name)}:</span> {item?.[header.property]?.length > 25 ? (item?.[header.property]?.slice(0, 21) + '...' ?? '-') : item?.[header.property] ?? '-'}</div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    );    

    return (
        <>
            <div className='call-center-wrapper'>
                <div className='call-center-header w-100'>
                    <div className='call-center-header-i'>
                        <img src={callCenter} alt='icone de atendimento' />
                        {t('callCenter.menu')}
                    </div>
                    <Button variant='primary' href='/call-center/new'>
                        <img src={newCallCenter} alt='icone de novo atendimento' />
                        {t('callCenter.newTicket')}
                    </Button>
                </div>
                <div className='d-flex flex-column w-100'>

                    <div className='call-center-search w-100'>
                        <div>
                            <UniInput
                                id='number'
                                label={t('callCenter.number')}
                                type='text'
                                value={search?.number ?? ''}
                                onChangeInput={(e) => onChangeNumber(e)}
                                disabled={loading}
                            />
                        </div>

                        <div style={{zIndex: 100}}>
                            <div style={{zIndex: 100}}>
                                <UniDatePickerRange
                                    loading={loading}
                                    periodValue={periodValue}
                                    onChange={e => onChangeDates(e)}
                                    options={[]}
                                    onHideChange={() => {}}
                                />
                            </div>
                        </div>

                        <div>
                            <UniInput
                                id='ticketLevel'
                                label={t('callCenter.labelSubject')}
                                isSelect
                                defaultValue={''}
                                value={search?.ticketLevel ?? ''}
                                onChange={(e) => onChangeTicketLevel(e)}
                                disabled={loading}
                            >
                                <option value={''}>{t('callCenter.ticketLevelAll')}</option>
                                {
                                    ticketLevels?.map((x, idx) => (
                                        <option key={idx} value={x.id}>
                                        {
                                            i18n.language === 'pt' ? x.descriptionPT : (i18n.language === 'es' ? x.descriptionES : x.descriptionEN )
                                        }
                                        </option>
                                    ))
                                }
                            </UniInput>
                        </div>

                        <div className='d-flex flex-column'>
                            <UniInput
                                id='status'
                                label='Status'
                                isSelect
                                value={search?.status ?? 'ALL'}
                                onChange={(e) => onChangeStatus(e)}
                                disabled={loading}
                            >
                                <option value={''}>{t('intervalAll')}</option>
                                <option value={TicketStatusEnum.Pending}>{t('callCenter.pending')}</option>
                                <option value={TicketStatusEnum.Served}>{t('callCenter.served')}</option>
                                <option value={TicketStatusEnum.Finished}>{t('callCenter.finished')}</option>
                            </UniInput>                            
                        </div>
                        <div title={t('search')} className='search-mobile' style={{ cursor: 'pointer', position: 'relative', top: '35px', height: 20 }} onClick={() => {sortField=''; setShowOpened(false); getTickets()}} ><FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faSearch}/></div>
                    </div>

                </div>

                <div className='d-flex flex-column w-100'></div>
                <div className='w-100 d-flex justify-content-center body-wrapper'>
                    <div className='table-uni'>
                        <div>
                            <div className='table-header'>
                                {
                                    dataTable.headers.map(header => (
                                        <div key={header.name} style={header.isStatus ? { width: 170 } : {}} className='header'>
                                            {
                                                <>
                                                    <div onClick={()=>{sortList(header.property)}} style={{ cursor: `pointer` }}>
                                                        {header.name}
                                                        {header.name && (sortField !== header.property + '=asc' && sortField !== header.property + '=desc') && <FontAwesomeIcon icon={faSort} />}
                                                        {header.name && sortField === header.property + '=asc' && <FontAwesomeIcon icon={faSortAmountDown} />}
                                                        {header.name && sortField === header.property + '=desc' && <FontAwesomeIcon icon={faSortAmountUp} />}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {
                            loading ? (
                                <Skeleton
                                    width={'100%'}
                                    height={400}
                                    duration={1}
                                    highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                    baseColor={darkMode ? '#021717' : '#ebebeb'} />
                            ) :
                                (
                                    showErrorModal || data?.length === 0 ? (<p className='w-100 text-center p-2'>{t('noResults')}</p>) :
                                    size?.width && size?.width <= 770 ?
                                        <MobileGrid />
                                    :
                                        <Accordion as={'div'} defaultActiveKey={showOpened ? '0' : null}>
                                        {
                                            data?.map((item: any, idx) => (
                                                <Accordion.Item as={'div'} eventKey={idx.toString()} key={idx.toString()}>
                                                    <div className='item-header'>
                                                        {
                                                            dataTable.headers.map((header, idxh) => {

                                                                if (header.isStatus) {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()} style={{ minWidth: 'fit-content', width: 165 }}>
                                                                            <div className='table-status' style={{ backgroundColor: callCenterStatusStyle.filter(s => s.name === item.status)?.[0]?.bgColor ?? '#fff', minWidth: 145 }}>
                                                                                <span className='table-status-icon' style={{ backgroundColor: callCenterStatusStyle.filter(s => s.name === item.status)?.[0]?.color ?? '#fff' }}></span>
                                                                                <div className='w-100' style={{ fontSize: 12, fontWeight: 700, minWidth: 'fit-content' }}>{getTicketDescriptionStatus(item?.[header.property])}</div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (header.isDate) {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()}>{item?.[header.property] ? moment(item?.[header.property]).format('DD/MM/yyyy HH:mm') : '-'}</div>
                                                                    )
                                                                }

                                                                if (header.property === 'ticketLevel') {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()}>{getTicketLevelDescription(item?.ticketLevel)}</div>
                                                                    )
                                                                }

                                                                if (header.property === 'actions') {
                                                                    return (
                                                                        <div className='action'>
                                                                            <div
                                                                                className='button-icon'
                                                                                onClick={() => gotoTicket(item.id)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faChevronRight} />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                return (
                                                                    <div key={idxh.toString() + idx.toString()} title={item?.[header.property] ?? '-'}>{item?.[header.property]?.length > 13 ? (item?.[header.property]?.slice(0, 12) + '...' ?? '-') : item?.[header.property] ?? '-'}</div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Accordion.Item>
                                            ))
                                        }
                                        </Accordion>
                                )
                        }
                    </div>
                </div>
                {!showErrorModal &&
                    <UniPagination totalItems={totalItems} totalPages={totalPages} changePage={(e: any) => setPage(e)} changeTake={(e: any) => { setPage(1); setTake(e) }} />
                }
            </div>
            
            <UniModal
                icon={faXmark}
                iconColor='#EA2027'
                isFontAwsome
                title={t('errorSearchMessage')}
                message={''}
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
            >    
                <p>{errorModal}</p>
                <button 
                    className='button-close no-margin' 
                    onClick={() => setShowErrorModal(false)}>
                    {t('close')}
                </button>
            </UniModal>
        </>
    );
}

export default CallCenter;