import '../styles/CallCenterNew.scss';
import { Form, Spinner } from 'react-bootstrap';
import callCenter from '../assets/icons/call-center.svg';
import circleCheckMarkGreen from '../assets/icons/circle-checkmark-green.svg';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import { createTicket, getTicketLevels, NewTicket,  ResponseTicketLevel } from '../services/callCenterService';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import UniInputFile, { UniInputFileReference } from '../components/UniInputFile/UniInputFile';
import UniModal from '../components/UniModal/UniModal';
import { useUserStore } from '../store/user';

const CallCenterNew = () => {    
    const { selectedCustomer } = useUserStore();
    const [data, setData] = useState<NewTicket>({
        ticketLevelId: '',
        telephone: '',
        description: ''
    });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModal, setErrorModal] = useState('');
    const [errorModalTitle, setErrorModalTitle] = useState('');
    const [ticketLevelError, setTicketLevelError] = useState(false);
    const [telephoneError, setTelephoneError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [ticketLevels, setTicketLevels] = useState<ResponseTicketLevel[]>();
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getTicketLevels().then(m => {
            if (m.success) {
                setTicketLevels(m.data);                    
            } else {                
                setErrorModal(
                    (i18n.language === 'pt' ? m.errorPT :
                    i18n.language === 'es' ? m.errorES :
                    m.errorEN ) ?? ''
                );
                setShowErrorModal(true);
                setErrorModalTitle(t('errorSearchMessage'));
            }
        })
        .catch(e => {
            console.log(e);
            setShowErrorModal(true);
            setErrorModal(e);
            setErrorModalTitle(t('errorSearchMessage'));
        })
        .finally(() => setLoading(false));
    }, []);    
    
    const onChangePhone = (e: ChangeEvent<FormControlElement>) => {
        const value = e?.target?.value.replace(/[^0-9]/, '');
        setData({...data, telephone: value});
    }

    const onChangeTicketLevel = (e: ChangeEvent<HTMLSelectElement>) => {
        setData({...data, ticketLevelId: e?.target?.value});
    }
    
    const onChangeDescription = (e: ChangeEvent<FormControlElement>) => {
        setData({...data, description: e?.target?.value});
    }

    const onFileAdd = (filesToAdd : File[]) => {
        setFiles([...files, ...filesToAdd]);
    }

    const onFileRemove = (file: (File | UniInputFileReference)) => {
        const fileToRemoveIndex = files.findIndex(x => x.name === file.name);
        files.splice(fileToRemoveIndex, 1);
        setFiles(files);
    }

    const validate = () => {
        setTicketLevelError(false);
        setTelephoneError(false);
        setDescriptionError(false);

        let isValid = true;
        if(!data?.ticketLevelId) {
            isValid = false;
            setTicketLevelError(true);
        }

        if(!data?.telephone) {
            isValid = false;
            setTelephoneError(true);
        }

        if(!data?.description) {
            isValid = false;
            setDescriptionError(true);
        }

        return isValid;
    }

    const onSaveMessage = () => {        
        if(!validate()){
            return
        }

        setLoading(true);
        createTicket(data, files, selectedCustomer.issuer).then(m => {
            if (m.success) {
                setShowSuccessModal(true);
            } else {                
                setErrorModal(
                    (i18n.language === 'pt' ? m.errorPT :
                    i18n.language === 'es' ? m.errorES :
                    m.errorEN ) ?? ''
                );
                setShowErrorModal(true);
                setErrorModalTitle(t('callCenter.errorMessage'));
            }
        })
        .catch(e => {
            console.log(e);
            setShowErrorModal(true);
            setErrorModalTitle(t('callCenter.errorMessage'));
            setErrorModal(e);
        })
        .finally(() => setLoading(false));
    }

    const onSuccessSave = () => {
        setShowSuccessModal(false);
        navigate(`/call-center`);
    }

    return (
        <>
            <div className='call-center-new-wrapper'>
                <div className='call-center-new-header w-100'>
                    <div className='call-center-new-header-i'>
                        <img src={callCenter} alt='icone de atendimento' />
                        {t('callCenter.newTicket')}
                    </div>
                </div>                
                <div className='w-100 d-flex justify-content-center body-wrapper'>
                    <div className='d-flex flex-column w-100'>
                        <p className='form-title'>                                            
                            {t('callCenter.newPageTitle')}
                        </p>
                        <div className='divider'></div>
                        <div className='row'>
                            <div className='col-md-6 first-column'>
                                <div className={ticketLevelError ? 'input-error' : ''}>
                                    <UniInput
                                        label={t('callCenter.labelSubject')} 
                                        id='subject' 
                                        value={data?.ticketLevelId} 
                                        isSelect 
                                        type='select'
                                        defaultValue={'0'} 
                                        onChange={(e) => onChangeTicketLevel(e)}
                                        loading={loading}
                                    >
                                        <option disabled value={''}>{t('callCenter.labelSubjectSelect')}</option>
                                        {
                                            ticketLevels?.map((x, idx) => 
                                            (
                                                <option key={idx} value={x.id}>
                                                {
                                                    i18n.language === 'pt' ? x.descriptionPT : 
                                                    (
                                                        i18n.language === 'es' ? x.descriptionES :
                                                        x.descriptionEN
                                                    )
                                                }                                            
                                                </option>
                                            ))
                                        }
                                    </UniInput>
                                    {ticketLevelError && (
                                        <p className='error-mandatory'>{t('fieldErrorMandatoryMessage')}</p>
                                    )}
                                </div>

                                <div className={telephoneError ? 'input-error' : ''}>
                                    <UniInput 
                                        label={t('callCenter.labelPhone')} 
                                        id='phone' 
                                        value={data?.telephone}
                                        type='tel'
                                        maxLength={12}
                                        placeholder={t('callCenter.labelPhonePlaceholder')}
                                        onChangeInput={(e) => onChangePhone(e)}                                    
                                    />
                                    {telephoneError && (
                                        <p className='error-mandatory'>{t('fieldErrorMandatoryMessage')}</p>
                                    )}
                                </div>

                                <Form.Group className={'description mb-3 ' + (descriptionError ? 'input-error' : '')}>
                                    <Form.Label>{t('callCenter.labelDescription')}</Form.Label>
                                    <Form.Control 
                                        as='textarea'
                                        rows={4} 
                                        maxLength={700}
                                        placeholder={t('callCenter.labelDescriptionPlaceholder')}
                                        value={data?.description} 
                                        onChange={(e) => onChangeDescription(e)} />
                                </Form.Group>
                                {descriptionError && (
                                    <p className='error-mandatory'>{t('fieldErrorMandatoryMessage')}</p>
                                )}
                            </div>
                            <div className='col-md-6 second-column'>
                                <UniInputFile
                                    canEdit={true}
                                    files={files}
                                    label={t('attachments')}
                                    fileExtensions={['.jpg', '.png', '.pdf']}
                                    onFileAdd={files => onFileAdd(files)}
                                    onFileRemove={file => onFileRemove(file)}
                                    fileRemoveModalTitle={t('fileRemoveModalTitle')}
                                    fileRemoveConfirmation={false}
                                />                                
                            </div>
                        </div>
                        <div className='buttons-wrapper'>
                            <button 
                                className='button-close' 
                                onClick={() => navigate(`/call-center`)}
                            >
                                {t('back')}
                            </button>
                            <button 
                                className='button-confirm' 
                                onClick={() => onSaveMessage()}
                                disabled={loading} 
                            >
                                {loading ? <Spinner/> : t('send')}
                            </button>
                        </div>
                    </div>
                </div>                
            </div>           
            <UniModal
                icon={faXmark}
                isFontAwsome
                iconColor='#EA2027'
                title={errorModalTitle}
                message={''}
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
            >    
                <p>{errorModal}</p>
                <button 
                    className='button-close no-margin' 
                    onClick={() => setShowErrorModal(false)}>
                    {t('close')}
                </button>
            </UniModal>
            <UniModal
                icon={circleCheckMarkGreen}
                title={t('callCenter.successMessage')}
                message={''}
                show={showSuccessModal}
                onHide={() => onSuccessSave()}
            >    
                <button 
                    className='button-close no-margin' 
                    onClick={() => onSuccessSave()}>
                    {t('close')}
                </button>
            </UniModal>
        </>
    );
}

export default CallCenterNew;